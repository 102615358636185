import useLocalStorage from "use-local-storage";
import { PageRoutes } from "./types";
import EVCountVerification from "./pages/EVCountVerification/EVCountVerification";
import Landing from "./pages/Landing/Landing";
import Placeholder from "./pages/Placeholder/Placeholder";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import EVSelectLocation from "./pages/EVSelectLocation/EVSelectLocation";
import SBLanding from "./pages/SBLanding/SBLanding";
import SBInitialCount from "./pages/SBInitialCount/SBInitialCount";
import SBInitialUnusedCount from "./pages/SBInitialUnusedCount/SBInitialUnusedCount";
import SBUnusedCountVerification from "./pages/SBUnusedCountVerification/SBUnusedCountVerification";
import SBCountVerification from "./pages/SBCountVerification/SBCountVerification";
import Initialize from "./pages/Initialize/Initialize";
import InitializeReporting from "./pages/InitializeReporting/InitializeReporting";
import SBReconciliation from "./pages/SBReconciliation/SBReconciliation";
import Reporting from "./pages/Reporting/Reporting";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import SBInitialSpoiledCount from "./pages/SBInitialSpoiledCount/SBInitialSpoiledCount";
import SBSpoiledCountVerification from "./pages/SBSpoiledCountVerification/SBSpoiledCountVerification";
import Info from "./pages/Info/Info";

function App() {
   // Unable to get the first click of the initialize to work.  Suspect it's some combination of these hooks and the old react-router-dom version.
   // Manuually testing the local storage in the gatekeep function seems to work consistently.
//   const [jwToken] = useLocalStorage<any>("jwToken", undefined);
//   const [jwTokenReporting] = useLocalStorage<any>(
//     "jwTokenReporting",
//     undefined
//   );

  const gatekeep = (type: "regular" | "reporting", component: JSX.Element) => {
    const manualReg = window.localStorage.getItem("jwToken");
    const manualReport = window.localStorage.getItem("jwTokenReporting");

    if (type === "regular" && manualReg) {
      return component;
    }
    if (type === "reporting" && manualReport) {
      return component;
    } else return <AccessDenied />;
  };

  return (
    <div className="app-content">
      <div className="background"></div>
      <Router>
        <Switch>
          <Route path={PageRoutes.initialize} exact>
            <Initialize />
          </Route>
          <Route path={PageRoutes.initializeReporting} exact>
            <InitializeReporting />
          </Route>
          <Route path={PageRoutes.landing} exact>
            <Landing />
          </Route>

          <Route path={PageRoutes.evCountVerification} exact render={() => {return gatekeep("regular", <EVCountVerification />)}} />
          <Route path={PageRoutes.evSelectLocation} exact render={() => {return gatekeep("regular", <EVSelectLocation />)}} />

         <Route path={PageRoutes.sbElectionDay} exact render={() => {return gatekeep("regular", <SBLanding />)}} />

          <Route path={PageRoutes.sbInitialCountDem} exact render={() => {return gatekeep("regular", <SBInitialCount party="dem"  />)}} />
          <Route path={PageRoutes.sbInitialCountRep} exact render={() => {return gatekeep("regular", <SBInitialCount party="rep"  />)}} />
          <Route path={PageRoutes.sbInitialCountLib} exact render={() => {return gatekeep("regular", <SBInitialCount party="lib"  />)}} />
          <Route path={PageRoutes.sbInitialCountGre} exact render={() => {return gatekeep("regular", <SBInitialCount party="grn"  />)}} />
          <Route path={PageRoutes.sbInitialCountNon} exact render={() => {return gatekeep("regular", <SBInitialCount party="non"  />)}} />

          <Route path={PageRoutes.sbCountVerification} exact render={() => {return gatekeep("regular", <SBCountVerification />)}} />

          <Route path={PageRoutes.sbSpoiledInitialCountDem} exact render={() => {return gatekeep("regular", <SBInitialSpoiledCount party="dem" />)}} />
          <Route path={PageRoutes.sbSpoiledInitialCountRep} exact render={() => {return gatekeep("regular", <SBInitialSpoiledCount party="rep" />)}} />
          <Route path={PageRoutes.sbSpoiledInitialCountLib} exact render={() => {return gatekeep("regular", <SBInitialSpoiledCount party="lib" />)}} />
          <Route path={PageRoutes.sbSpoiledInitialCountGre} exact render={() => {return gatekeep("regular", <SBInitialSpoiledCount party="grn" />)}} />
          <Route path={PageRoutes.sbSpoiledInitialCountNon} exact render={() => {return gatekeep("regular", <SBInitialSpoiledCount party="non" />)}} />

          <Route path={PageRoutes.sbSpoiledCountVerification} exact render={() => {return gatekeep("regular", <SBSpoiledCountVerification />)}} />

          <Route path={PageRoutes.sbUnusedInitialCountDem} exact render={() => {return gatekeep("regular", <SBInitialUnusedCount party="dem" />)}} />
          <Route path={PageRoutes.sbUnusedInitialCountRep} exact render={() => {return gatekeep("regular", <SBInitialUnusedCount party="rep" />)}} />
          <Route path={PageRoutes.sbUnusedInitialCountLib} exact render={() => {return gatekeep("regular", <SBInitialUnusedCount party="lib" />)}} />
          <Route path={PageRoutes.sbUnusedInitialCountGre} exact render={() => {return gatekeep("regular", <SBInitialUnusedCount party="grn" />)}} />
          <Route path={PageRoutes.sbUnusedInitialCountNon} exact render={() => {return gatekeep("regular", <SBInitialUnusedCount party="non" />)}} />

          <Route path={PageRoutes.sbUnusedCountVerification} exact render={() => {return gatekeep("regular", <SBUnusedCountVerification />)}} />

          <Route path={PageRoutes.sbReconciliation} exact render={() => {return gatekeep("regular", <SBReconciliation />)}} />

          <Route path={PageRoutes.placeholder} exact render={() => {return gatekeep("regular", <Placeholder />)}} />

          <Route path={PageRoutes.info} exact render={() => {return gatekeep("regular", <Info />)}} />

          <Route path={PageRoutes.reporting} exact render={() => {return gatekeep("reporting", <Reporting />)}} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
