
export enum PageRoutes {
    landing = "/",

    // received the incoming request with the webtoken and then stores it.
    initialize = "/initialize",
    // received for assigning a unique token for reporting only.
    initializeReporting = "/initializeReporting",

    evCountVerification = "/early-voting/count-verification",
    evSelectLocation =    "/early-voting",
   //  evInitialCount =      "/early-voting/initial-count",
    sbElectionDay =       "/election-day",

    sbInitialCountDem =      "/election-day/initial-count/dem",
    sbInitialCountRep =      "/election-day/initial-count/rep",
    sbInitialCountLib =      "/election-day/initial-count/lib",
    sbInitialCountGre =      "/election-day/initial-count/gre",
    sbInitialCountNon =      "/election-day/initial-count/non",
    
    sbCountVerification = "/election-day/count-verification",
    
    sbUnusedInitialCountDem =      "/election-day/unused/initial-count/dem",
    sbUnusedInitialCountRep =      "/election-day/unused/initial-count/rep",
    sbUnusedInitialCountLib =      "/election-day/unused/initial-count/lib",
    sbUnusedInitialCountGre =      "/election-day/unused/initial-count/gre",
    sbUnusedInitialCountNon =      "/election-day/unused/initial-count/non",
    
    sbSpoiledInitialCountDem =      "/election-day/spoiled/initial-count/dem",
    sbSpoiledInitialCountRep =      "/election-day/spoiled/initial-count/rep",
    sbSpoiledInitialCountLib =      "/election-day/spoiled/initial-count/lib",
    sbSpoiledInitialCountGre =      "/election-day/spoiled/initial-count/gre",
    sbSpoiledInitialCountNon =      "/election-day/spoiled/initial-count/non",
    
    sbUnusedCountVerification =    "/election-day/unused/count-verification",
    sbSpoiledCountVerification =    "/election-day/spoiled/count-verification",
    sbReconciliation =      "/election-day/reconciliation",
    placeholder = "/placeholder",

    // show some config information, including current build
    info = "/info",

    reporting = "/reporting",
}

export interface ICheckInData {
    receiptCount: number;
    checkInCount: number;
    provisionalVoterCount: number;
    scannerResultCount: number;
    totalVoterCount?: number;
    mismatchReason?: string;
    confirmed: boolean;
}

export type Location = {
    // availableEndDate: string;
    // availableStartDate: string;
    // createdAt: string;
    // updatedAt: string;
    value: string;
    displayText: string;
    vscCode: string;
    _id: string;
};

//
// Used for the ED - Election Day since the data model is different.
//
export interface EDLocation {
    _id: string;
    township: string;
    precint: string;
    vscCode: string;

    edPartyBallots: EdPartyBallot[];
}

export interface EdPartyBallot {
    edParty:        EdParty;
    edBallotStyles: EdBallotStyle[];
}

export interface EdBallotStyle {
    ballotStyleNumber: string;
}

export interface EdParty {
    identifier: string;
    partyName:  string;
}

// NOTE: change electionDay.controller.js validation
export type PartyKey = 'dem' | 'rep' | 'lib' | 'grn' | 'non';

export enum Party {
    dem = "Democrat",
    rep = "Republican",
    lib = "Libertarian",
    grn = "Green",
    non = "Non-Affiliated"
}

/// Old design
export type CountParty = {
    party: {identifier: PartyKey, partyName: Party},
    ballotStyle8230: Number,
    ballotStyle8231: Number,
    // ballotStyle8232: Number
}

/// New transient CountParty object
export type CountParty2 = {
    edParty: {identifier: PartyKey, partyName: Party},
    edBallotStyleTotals: EdBallotStyleTotal[];
}

/// New Design

/// TODO edLocation - Add a type for partyUnusedTotals
export interface ElectionDay {
    edLocation:          EdLocation;
    _id:                 string;
    date:                Date;
    completed:           boolean;
    edPartyTotals:       EdPartyTotal[];
    edPartyUnusedTotals: EdPartyTotal[];
    edPartySpoiledTotals: EdPartyTotal[];
    applicationReceipts: number;
    applicationCheckins: number;
    ballotsProvisional:  number;
    ballotsScanner:      number;
    inputtedText:        string;
    createdAt:           Date;
    updatedAt:           Date;
    __v:                 number;
}

export interface EdPartyTotal {
    edParty:             EdParty;
    edBallotStyleTotals: EdBallotStyleTotal[];
}

/// for election day data.
export interface EdBallotStyleTotal {
    ballotStyleNumber: string;
    ballotValue:       number;
}


/// election day locations
export interface EdLocation {
    township:   string;
    precint:    string;
    vscCode:    string;
    edPartyBallots: EdPartyBallot[];
}


export interface EdPartyBallot {
    edParty:        EdParty;
    edBallotStyles: EdBallotStyle[];
}

export interface EdBallotStyle {
    ballotStyleNumber: string;
}



/// shared object
export interface EdParty {
    identifier: string;
    partyName:  string;
}
