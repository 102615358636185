import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import StaticTextDialog from '../../components/StaticTextDialog/StaticTextDialog';
import "./PreviousDayConfirmationDialog.scss";
import { DateTime } from 'luxon';

  // TODO NN fully use this interface for the date displayed.
  interface IPreviousDayConfirmationProps {
    confirmDate: string;
    show?: boolean;
    onHide?: () => void;
    onConfirm?: () => void;
  }

  function PreviousDayConfirmationDialog(props: IPreviousDayConfirmationProps) {
    return (
        <StaticTextDialog
          show={props.show}
          onHide={props.onHide}
          header="Previous Day Count Confirmation"
          footer={
            <>
              <Button className="btn-warning spread" onClick={props.onHide}>Cancel</Button>
              <Button className="btn-primary spread" onClick={props.onConfirm}>Confirm and Open {DateTime.now().toFormat('MMMM d, yyyy')}</Button>
            </>
          }
          headerSize="size25"
        >
          By submitting, you are confirming that the number from {props.confirmDate} are accurate.
          If they are not accurate, please call (312) 603-1178 for assistance.
        </StaticTextDialog>
    );
  }

export default PreviousDayConfirmationDialog;

