import Button from 'react-bootstrap/Button';

import './Landing.scss';
import { PageRoutes, Location, EDLocation, CountParty2 } from '../../types';
import useLocalStorage from 'use-local-storage';
import { Redirect, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import CountySeal from '../../components/CountySeal/CountySeal';
import StaticTextDialog from '../../components/StaticTextDialog/StaticTextDialog';
import { getConfig } from '../../util/config';


const NotYetDialog = (props: any) =>
  <StaticTextDialog
    show={props.show}
    onHide={props.onHide}
    header="Area Not Available"
    footer={
      <Button className="btn-primary spread" onClick={props.onHide}>Ok</Button>
    }
    headerSize="size25"
  >
    This area of the app is unavailable today ({DateTime.now().toFormat("LLLL d, yyyy")}).
    It is only available {props.dateRangeInWords} {props.edStartDate.toFormat("LLLL d, yyyy")}.
  </StaticTextDialog>

function Landing() {
  const [cachedData, setCachedData] = useLocalStorage<any>("cachedData", undefined)

  const [asOfDate, setAsOfDate] = useLocalStorage<string>("asOfDate", DateTime.now().toISODate());

  const [location, setLocation] = useLocalStorage<Location | null>('location', null);
  const [edLocation, setEDLocation] = useLocalStorage<EDLocation | null>('edLocation', null);

  const [, setElectionDayObject] = useLocalStorage<any | null>("electionDayObject", null);
  const [, setPartyTotalsList] = useLocalStorage<CountParty2[] | null>("partyTotalsList", []);
  const [, setPartyUnusedTotalsList] = useLocalStorage<CountParty2[]>("partyUnusedTotalsList", []);
  const [, setPartySpoiledTotalsList] = useLocalStorage<CountParty2[]>("partySpoiledTotalsList", []);

  const [showPreventEarlyVotingDialog, setShowPreventEarlyVotingDialog] = useState(false);
  const [showPreventElectionDayDialog, setShowPreventElectionDayDialog] = useState(false);
  const history = useHistory();
  const config = getConfig();

  useEffect(() => {
    setLocation(null);
    
    setEDLocation(null);
    setElectionDayObject(null);

    setPartyTotalsList([]);
    setPartyUnusedTotalsList([]);
    setPartySpoiledTotalsList([]);

    setAsOfDate(DateTime.now().toISODate());
  }, [])

  function handleEarlyVotingClick() {
    let triggerDialog = DateTime.fromISO(asOfDate) < config.evStartDate || DateTime.fromISO(asOfDate) >= config.edStartDate;
      if (config.allowEVEDParallel) {
         triggerDialog = triggerDialog && DateTime.fromISO(asOfDate) < config.edStartDate;
      }
    if(triggerDialog)
      setShowPreventEarlyVotingDialog(true);
    else {


      (window as any).DateTime = DateTime;

      if (!location) {

        const tmp = JSON.parse(localStorage.getItem('cachedData') || JSON.stringify({
          receiptCount: 0,
          checkInCount: 0,
          provisionalVoterCount: 0,
          scannerResultCount: 0,
        }));

        setCachedData(tmp);

        history.push(PageRoutes.evSelectLocation)
      } else {
        // const asOfDateValue = DateTime.fromISO(asOfDate).startOf('day');
        // console.log(asOfDate);
        // const today = DateTime.now().startOf('day');
        // const yesterday = today.plus({ days: -1 }).startOf('day');
        // if (!asOfDate.equals(yesterday) && !asOfDate.equals(today)) {
          // cached data is not from yesterday or today; clear it out
          // setCachedData(undefined);
        // }
        
        setTimeout(() => {
          history.push(PageRoutes.evCountVerification);
        }, 0);
      }
    }
  }

  const handleElectionDayClick = () => {
    // const triggerDialog = false;
   //  const triggerDialog = DateTime.fromISO(asOfDate) < config.edStartDate || DateTime.fromISO(asOfDate) > config.edStartDate;

   // Allowing start date on and after edStartDate.  This allows initial counts before the election.  The eod counts are guarded by config.edNightStart.
   const triggerDialog = DateTime.fromISO(asOfDate) < config.edPreCount;
    if(triggerDialog && !config.allowEVEDParallel)
      setShowPreventElectionDayDialog(true);
    else history.push(PageRoutes.sbElectionDay);
  }

  return (
    <div className="landing">
        <CountySeal />
        <div className="button-wrap">
          <Button
            className="large-button btn-lg"
            variant="primary"
            onClick={handleEarlyVotingClick}
          >
            Early Voting
          </Button>
          <Button
            className="large-button btn-lg"
            variant="primary"
            onClick={handleElectionDayClick}
          >
            Election Day
          </Button>
        </div>
        <NotYetDialog
          show={showPreventEarlyVotingDialog}
          onHide={() => setShowPreventEarlyVotingDialog(false)}
          dateRangeInWords={config.preventEVMessage}
          edStartDate={config.edStartDate}
        />
        <NotYetDialog
          show={showPreventElectionDayDialog}
          onHide={() => setShowPreventElectionDayDialog(false)}
          dateRangeInWords={config.preventEDMessage}
          edStartDate={config.edStartDate}
        />
    </div>
  );
}

export default Landing;