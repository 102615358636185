import './SBInitialSpoiledCount.scss';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SBPageHeader from '../../components/SBPageHeader/SBPageHeader';
import Button from 'react-bootstrap/Button';
import useLocalStorage from 'use-local-storage';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';

import StaticTextDialog from '../../components/StaticTextDialog/StaticTextDialog';
import { EDLocation, PageRoutes, Party, PartyKey, CountParty2 } from '../../types';
import { buildTitle } from '../../util/title';
import { getConfig } from '../../util/config';

const serviceURL : string = (process.env.REACT_APP_SERVICE_URL as string);

const CompletedLocationDialog = ({show, onHide}: {show: Boolean, onHide: any}) => <StaticTextDialog
  show={show}
  header="Precinct Complete"
  onHide={onHide}
  footer={<Button 
    variant="primary"
    className="ok-button"
    onClick={onHide}
  >
    OK
  </Button>}
  headerSize="size25"
  className="completed-location"
  >
  This precinct has already been completed. If you need to make changes, contact technical support.
</StaticTextDialog>;


function SBInitialSpoiledCount(props: {party: PartyKey}) {
  const [edLocation, setEDLocation] = useLocalStorage<EDLocation | null>('edLocation', null);
  const [jwToken] = useLocalStorage<any>("jwToken", undefined);
  // const [asOfDate, ] = useLocalStorage<string>("asOfDate", DateTime.now().toISODate());
  const [electionDayObject, setElectionDayObject] = useLocalStorage<any>("electionDayObject", undefined);
  const [partySpoiledTotalsList, setPartySpoiledTotalsList] = useLocalStorage<CountParty2[]>("partySpoiledTotalsList", []);
  const [showCompletedLocationDialog, setShowCompletedLocationDialog] = useState(false);

  const [ballotStyles, setBallotStyles] = useState<string[]>([]);
  const [ballotCounts, setBallotCounts] = useState<{ [key: string]: string }>({});

  const [showTooEarlyDialog, setShowTooEarlyDialog] = useState(false);

  const history = useHistory();
  const config = getConfig();


  useEffect(() => {
    if (DateTime.now() < config.edNightStart) {
      setShowTooEarlyDialog(true);
      return;
    }

    setBallotStyles(edLocation?.edPartyBallots.find(x => x.edParty.identifier === props.party)?.edBallotStyles.map(x => x.ballotStyleNumber) || []);

    axios.get(serviceURL + `/electionDay/latest/${edLocation?._id}`,
    {
      headers: {
        'x-access-token': jwToken
      }
    })
    .then(result => {
      setElectionDayObject(result.data);
      const partyObject : CountParty2 = result.data?.edPartySpoiledTotals.find((partyTotalsObject: any) => 
          partyTotalsObject.edParty.identifier === props.party
      );
      setBallotCounts(partyObject.edBallotStyleTotals.reduce((p, c) => { p[c.ballotStyleNumber] = `${c.ballotValue}`; return p; }, {} as { [key: string]: string }));
    })
    .catch(error => {
      console.log(error);
      setBallotCounts({});
    });
  }, [props.party])

  function contiueClicked() {
    setPartySpoiledTotalsList([
      ...partySpoiledTotalsList.filter(x => x.edParty.identifier !== props.party),
      {
        edParty: {
          identifier: props.party,
          partyName: Party[props.party]
        },
        edBallotStyleTotals: ballotStyles.map(ballotStyle => ({ballotStyleNumber: ballotStyle, ballotValue: parseInt(ballotCounts[ballotStyle]) || 0})),
      }
    ]);

    setTimeout(() => {
      if (edLocation != null) {
        var dems = edLocation.edPartyBallots.find(pb => pb.edParty.identifier == "dem");
        var reps = edLocation.edPartyBallots.find(pb => pb.edParty.identifier == "rep");
        var libs = edLocation.edPartyBallots.find(pb => pb.edParty.identifier == "lib");
        var grns = edLocation.edPartyBallots.find(pb => pb.edParty.identifier == "grn");
        var nons = edLocation.edPartyBallots.find(pb => pb.edParty.identifier == "non");

        console.log('edLocation', edLocation);
        console.log('fields', dems, reps, libs, grns, nons);
  
        // history.push(PageRoutes.sbSpoiledCountVerification);
        if(props.party === "dem") {
          if (reps != null) {
            history.push(PageRoutes.sbSpoiledInitialCountRep);
          }
          else if (libs != null) {
            history.push(PageRoutes.sbSpoiledInitialCountLib);
          }
          else if (grns != null) {
            history.push(PageRoutes.sbSpoiledInitialCountGre);
          }
          else if (nons != null) {
            history.push(PageRoutes.sbSpoiledInitialCountNon);
          }
          else {
            history.push(PageRoutes.sbSpoiledCountVerification);
          }
        }
        else if(props.party === "rep") {

          if (libs != null) {
            history.push(PageRoutes.sbSpoiledInitialCountLib);
          }
          else if (grns != null) {
            history.push(PageRoutes.sbSpoiledInitialCountGre);
          }
          else if (nons != null) {
            history.push(PageRoutes.sbSpoiledInitialCountNon);
          }
          else {
            history.push(PageRoutes.sbSpoiledCountVerification);
          }
        }
        else if(props.party === "lib") {          
          if (grns != null) {
            history.push(PageRoutes.sbSpoiledInitialCountGre);
          }
          else if (nons != null) {
            history.push(PageRoutes.sbSpoiledInitialCountNon);
          }
          else {
            history.push(PageRoutes.sbSpoiledCountVerification);
          }
        }
        else if(props.party === "grn") {
          if (nons != null) {
            history.push(PageRoutes.sbSpoiledInitialCountNon);
          }
          else {
            history.push(PageRoutes.sbSpoiledCountVerification);
          }
        }
        else if(props.party === "non") {

          /// figure above edge cases for verification.
          history.push(PageRoutes.sbSpoiledCountVerification);
        }
      }
    }, 0);
  }

  const TooEarlyDialog = (props: any) =>
  <StaticTextDialog
    show={props.show}
    onHide={props.onHide}
    header="Area Not Available Yet"
    footer={
      <Button className="btn-primary spread" onClick={props.onHide}>Ok</Button>
    }
    headerSize="size25"
  >
    This area of the app is not available yet.  
    It will be available after {config.edNightStart.toLocaleString(DateTime.TIME_SIMPLE)} 
  </StaticTextDialog>


  return (
    <>
      <Container fluid className="sb-initial-count">
        <SBPageHeader edLocation={edLocation} title={buildTitle('End of Night Spoiled Ballot Count', props.party)} />
        <Row className="noTopMargin">
            <Col>
                <Row>
                <Col>
                    Enter the number of spoiled paper ballots in the boxes below.
                </Col>
                </Row>

                {ballotStyles.map(ballotStyle => (
                  <Row key={ballotStyle}>
                    <Col>Ballot Style Spoiled {ballotStyle}:</Col>
                    <Col>
                      <input type="text" value={ballotCounts[ballotStyle] || ""} maxLength={7} onChange={evt => isNaN(Number(evt.target.value)) || setBallotCounts({ ...ballotCounts, [ballotStyle]: evt.target.value || "" })} placeholder="Tap to specify" />              
                    </Col>
                  </Row>
                ))}
            </Col>
        </Row>

        <div className="restOfPage">
            <Button
                variant="primary"
                className="large-button"
                onClick={contiueClicked}
                disabled={ballotStyles.filter(ballotStyle => !ballotCounts[ballotStyle]).length !== 0}
            >
              Submit
            </Button>
        </div>
      </Container>

      <CompletedLocationDialog
        show={showCompletedLocationDialog}
        onHide={() => {
          setShowCompletedLocationDialog(false);
          setEDLocation(null);
          window.setTimeout(() => {
            history.replace(PageRoutes.landing);
          }, 0);
        }}
      />

      <TooEarlyDialog
          show={showTooEarlyDialog}
          onHide={() => {
            setShowTooEarlyDialog(false);
            setEDLocation(null);
            window.setTimeout(() => {
              history.replace(PageRoutes.landing);
            }, 0);
          }}
        />

    </>

  );
}

/// https://www.cookcountyclerkil.gov/elections/polling-places ///
/// https://www.cookcountyclerkil.gov/elections/ways-to-vote/early-voting/early-voting-locations ///


export default SBInitialSpoiledCount;