import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import "./InfoModal.scss";

interface IEVInfoModalProps {
    message?: String;
}

function InfoModal(props: any) {

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="modal-title"
        centered
        className="info-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-title" className="w-100">
            Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {props.message}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="spread" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default InfoModal