import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import "./CountMismatchDialog.scss";

function CountMismatchDialog(props: any) {

    const [inputText, setInputText] = React.useState(props.reason || "");

    useEffect(() => {
      setInputText(props.reason || "");
    }, [props.reason]);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="modal-title"
        centered
        className="count-mismatch-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-title" className="w-100">
            Count Mismatch
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The number of ballots cast does not match the number of check-ins.
            Verify the counts are correct. If they are correct, explain the reason
            for the discrepancy below:
          </p>
          <textarea
            className="form-control"
            name="explanation"
            value={inputText}
            onChange={(event) => setInputText(event.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="spread" onClick={props.onHide}>Cancel</Button>
          <Button variant="primary" className="spread" onClick={() => props.onSubmit(inputText)} disabled={inputText.trim().length === 0}>Save With Mismatch</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default CountMismatchDialog