import './EVSelectLocation.scss';
import React, {useEffect} from 'react';
import Select from 'react-select';
import { Container, Row, Col } from 'react-bootstrap';
import { DateTime } from 'luxon';
import Button from 'react-bootstrap/Button';
import useLocalStorage from 'use-local-storage';
import { useHistory } from 'react-router-dom';
import { PageRoutes, ICheckInData, Location } from '../../types';
import CountySeal from '../../components/CountySeal/CountySeal';


const serviceURL : string = (process.env.REACT_APP_SERVICE_URL as string);


function EVSelectLocation(props: any) {
  
  const [selectedOption, setSelectedOption] = React.useState<Location | null>(null);

  const [, setLocation] = useLocalStorage<Location | null>('location', null);
  const [jwToken] = useLocalStorage<any>("jwToken", undefined);

  const [items, setItems] = React.useState([]);
  const [cachedData,] = useLocalStorage<ICheckInData | undefined>('cachedData', undefined);
  const [asOfDate,] = useLocalStorage<string>("asOfDate", DateTime.now().toISODate());
  const [formattedDate, setFormattedDate] = React.useState("");
  
  useEffect(() => {
   // console.log('fetch ', serviceURL + "/locations")
    fetch(serviceURL + "/locations", {
      headers: {
        'x-access-token': jwToken
      }
    })
      .then(res => res.json())
      .then((result) => {
          console.log('as of date', asOfDate);
          console.log('in result: ', result.length);
          const locationsForCurrentDate = result.filter(({availableStartDate, availableEndDate}: {availableStartDate: string, availableEndDate: string}) => {
            // if(DateTime.fromISO(asOfDate) < DateTime.local(2022, 6, 1))
            //   return true;
            
            return DateTime.fromISO(asOfDate) < DateTime.fromISO(availableEndDate)
              && DateTime.fromISO(asOfDate) >= DateTime.fromISO(availableStartDate)
          })
          setItems(locationsForCurrentDate);
          console.log('modified locations ', locationsForCurrentDate.length);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log('weird handler: ', error)
        }
      ).catch(err => {
         console.log('error: ', err);
      });
  }, [])



  useEffect(() => {
   console.log('useEffect [cachedData]')
    console.log('cachedData ', cachedData);
    // Parse and format the date

    if (DateTime.fromISO(asOfDate).isValid) {
      const date = DateTime.fromISO(asOfDate).toFormat("LLLL d, yyyy");      
      setFormattedDate(date);
    }
    else {
      console.log('useEffect date not valid');
    }

    console.log(formattedDate);

  }, [cachedData]);


  const history = useHistory();

  function contiueClicked() {
    setLocation(selectedOption);
    // Using `setTimeout` here to work around a bug / bad interaction between `react-router-dom` and `use-local-storage`
    setTimeout(() => {
      history.push(PageRoutes.evCountVerification);
    }, 0);
  }

  return (
    <>
      <Container className="ev-select-location">
        <Row>
          <Col><CountySeal /></Col>
        </Row>

        <Row>
          <Col>
            <h1>Cook County Clerk's Office</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2>Early Voting - {formattedDate}</h2>
          </Col>
        </Row>

        <Row>
          <Col>
            Start typing to select your location:
          </Col>
        </Row>

        <Row>
            <Col>
              <Select
                  name="selectLocation"
                  value={selectedOption}
                  onChange={(selectedOption) => setSelectedOption(selectedOption)}
                  placeholder="Start typing here..."
                  options={items}
                  getOptionLabel={(option) => option.displayText}
                  getOptionValue={(option) => option.value}
              />
            </Col>
        </Row>
        <div className="restOfPage">
        <Button
          variant="primary"
          className="ev-select-location-primary-button large-button"
          onClick={contiueClicked}
          disabled={!selectedOption}
        >
          Continue
        </Button>
      </div>
      </Container>

    </>

  );
}

/// https://www.cookcountyclerkil.gov/elections/polling-places ///
/// https://www.cookcountyclerkil.gov/elections/ways-to-vote/early-voting/early-voting-locations ///


export default EVSelectLocation;