import Button from "react-bootstrap/Button";

import "./Initialize.scss";
import { PageRoutes } from "../../types";
import useLocalStorage from "use-local-storage";
import { Redirect, useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import CountySeal from "../../components/CountySeal/CountySeal";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

interface PropTypes {}

function Initialize(props: PropTypes) {
  const [, setJwToken] = useLocalStorage<any>("jwToken", undefined);
  const history = useHistory();

  const search = useLocation().search;

  const jwt = new URLSearchParams(search).get("jwt");
  console.log("jwt is: ", jwt); //12345

  useEffect(() => {
    if (!!jwt) {
      console.log('setting token');
      setJwToken(jwt);

      window.setTimeout(() => {
        history.replace(PageRoutes.landing);
      }, 100);
    }
  }, []);

  return (
    <div className="initialize">
      <CountySeal />
      <Row>
        <Col>
          <h1>Configuring application...</h1>
        </Col>
      </Row>
      {/* <div className="button-wrap">
          <Button className="large-button btn-lg" variant="primary">Temp Button</Button>
        </div> */}
    </div>
  );
}

export default Initialize;
