import './SBUnusedCountVerification.scss';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import SBPageHeader from '../../components/SBPageHeader/SBPageHeader';
import StaticTextDialog from '../../components/StaticTextDialog/StaticTextDialog';
import Button from 'react-bootstrap/Button';
import useLocalStorage from 'use-local-storage';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { EDLocation, PageRoutes, CountParty2 } from '../../types';

const serviceURL : string = (process.env.REACT_APP_SERVICE_URL as string);
const isGeneralElection : boolean = (process.env.REACT_APP_IS_GENERAL_ELECTION as unknown as boolean)

const CountSubmittedDialog = ({show, onHide}: {show: Boolean, onHide: any}) => <StaticTextDialog
    show={show}
    header="Unused Count Submitted"
    onHide={onHide}
    footer={<Button 
      variant="primary"
      className="w-50"
      onClick={onHide}
    >
      OK
    </Button>}
    headerSize="size25"
    className="count-submitted"
  >
    The unused counts have been successfully submitted!
</StaticTextDialog>;

function SBUnusedCountVerification() {
  const [showCountSubmittedDialog, setShowCountSubmittedDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [edLocation, setEDLocation] = useLocalStorage<EDLocation | null>('edLocation', null);
  const [electionDayObject, setElectionDayObject] = useLocalStorage<any>("electionDayObject", undefined);
  const [partyUnusedTotalsList, setPartyUnusedTotalsList] = useLocalStorage<CountParty2[]>("partyUnusedTotalsList", []);
  const [jwToken] = useLocalStorage<any>("jwToken", undefined);

  const [ballotStyles, setBallotStyles] = useState<string[]>([]);
  const [parties, setParties] = useState<string[]>([]);

  useEffect(() => {
    setBallotStyles(partyUnusedTotalsList.flatMap(x => x.edBallotStyleTotals.map(y => y.ballotStyleNumber)).sort().filter((x, i, a) => i === a.indexOf(x)));
    setParties(edLocation?.edPartyBallots.map(x => x.edParty.identifier) || []);

    axios.get(serviceURL + `/electionDay/latest/${edLocation?._id}`,
    {
      headers: {
        'x-access-token': jwToken
      }
    })
    .then(result => {
      setElectionDayObject(result.data);
    })
    .catch(error => {
      console.log(error);
    })
  }, [])

  const history = useHistory();

  function contiueClicked() {
   setSubmitted(true);
    if(!electionDayObject._id) {

      /// TODO edLocation - resolve below post with new design of the db table.
      axios.post(serviceURL + "/electionDay",
        {
          location: {
            locationId: edLocation?._id,
            township: edLocation?.township,
            precint: edLocation?.precint,
            vscCode: edLocation?.vscCode
          },
          date: DateTime.fromISO(DateTime.now().toISODate()).toISO(), // make sure to only set this on creation
          applicationReceipts: 0,
          applicationCheckins: 0,
          ballotsProvisional: 0,
          ballotsScanner: 0,
          completed: false,
          inputtedText: "",
          partyTotals: partyUnusedTotalsList
        }, {
          headers: {
            'x-access-token': jwToken
          }
        }
      )
      .then((result) => {
          console.log(result);
          setElectionDayObject(typeof result.data === "string" ? {} : result.data);
          setPartyUnusedTotalsList([]);

      })
      .catch((error) => {
          console.log(error)
          setPartyUnusedTotalsList([]);

      });
    } else {
      axios.put(serviceURL + `/electionDay/${electionDayObject._id}`, // should never happen
        {
          ...electionDayObject,
          edPartyUnusedTotals: partyUnusedTotalsList
        }, {
          headers: {
            'x-access-token': jwToken
          }
        }
      )
      .then((result) => {
          console.log(result);
          setElectionDayObject(typeof result.data === "string" ? {} : result.data);
          setPartyUnusedTotalsList([]);

      })
      .catch((error) => {
          console.log(error)
          setPartyUnusedTotalsList([]);

      });
    }
    setShowCountSubmittedDialog(true);
  }

  const goToReconciliationPage = () => {
    window.setTimeout(() => {
      history.replace(PageRoutes.sbReconciliation);
    }, 0);
  }

  const countsObject = partyUnusedTotalsList.reduce((acc: any, currPartyTotal: CountParty2) => {
    acc[currPartyTotal.edParty.identifier] = currPartyTotal;
    return acc;
  }, {});


  return (
    <>
      <Container fluid className="sb-count-verification">
        <SBPageHeader edLocation={edLocation} title={`Confirm Unused Ballot Count`} />
        <Row className="noTopMargin">
          <Col>
              <Row>
                <Col>
                  Please verify that these counts are correct before submitting.
                </Col>
              </Row>

              <Table bordered hover variant="light">
                <thead>
                  <tr>
                    <th>Style</th>
                    {isGeneralElection ?
                    <th key={parties[0]}>{'Count'}</th>
                    :
                      <div>
                        {parties.map(party => (
                          <th key={party}>{party[0].toUpperCase()}{party.substring(1)}</th>
                        ))}
                      </div>
                  }
                  </tr>
                </thead>
                <tbody>
                {ballotStyles.map(ballotStyle => (
                    <tr key={ballotStyle}>
                      <td>{ballotStyle}</td>
                      { isGeneralElection ?
                      <td key={parties[0]}>{countsObject[parties[0]]?.edBallotStyleTotals?.find((x:any) => x.ballotStyleNumber === ballotStyle)?.ballotValue}</td>
                      :
                        <div>
                          {parties.map(party => (
                            <td key={party}>{countsObject[party]?.edBallotStyleTotals?.find((x:any) => x.ballotStyleNumber === ballotStyle)?.ballotValue}</td>
                          ))}
                        </div>
                    }
                      {/*  */}
                    </tr>
                  ))}
                </tbody>
              </Table>
          </Col>
        </Row>

        <div className="restOfPage">
            <Button
                variant="primary"
                className="large-button"
                onClick={contiueClicked}
                disabled={submitted}
            >
              Submit
            </Button>
        </div>
      </Container>
      <CountSubmittedDialog
        show={showCountSubmittedDialog}
        onHide={goToReconciliationPage}
      />
    </>

  );
}

/// https://www.cookcountyclerkil.gov/elections/polling-places ///
/// https://www.cookcountyclerkil.gov/elections/ways-to-vote/early-voting/early-voting-locations ///


export default SBUnusedCountVerification;