import './CountySeal.scss';
import React, { TouchEventHandler, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import blackSealLogo from '../../images/CC Seal_Black.png';
import { EDLocation, PageRoutes } from '../../types';
import StaticTextDialog from '../StaticTextDialog/StaticTextDialog';
import { DateTime } from 'luxon';

interface ICountySealProps {
}

const longPressDuration = 500;

function CountySeal(props: ICountySealProps) {
  const [modalShown, setModalShown] = useState(false);
  const [, setCachedData] = useLocalStorage<any>("cachedData", undefined);
  const [, setAsOfDate] = useLocalStorage<string>("asOfDate", DateTime.now().toISODate());
  const [, setLocation] = useLocalStorage<any>("location", undefined)
  const [, setEDLocation] = useLocalStorage<EDLocation | null>('edLocation', null);

  const [touchStart, setTouchStart] = useState<number>();
  const [touchTimeout, setTouchTimeout] = useState<number>();
  const history = useHistory();
  const [dateString, setDateString] = useState<string>();

  function showDebugModal() {
    const tmp = JSON.parse(localStorage.getItem('cachedData') || JSON.stringify({
      receiptCount: 0,
      checkInCount: 0,
      provisionalVoterCount: 0,
      scannerResultCount: 0,
    }));
    setCachedData(tmp);
    setModalShown(true);
  }

  function handleTouchStart(evt: React.TouchEvent<HTMLElement>) {
    evt.preventDefault();
    evt.stopPropagation();
    if (touchTimeout) {
      // ignore multi-touch
      return false;
    }
    const timeoutId = window.setTimeout(() => {
      showDebugModal();
      setTouchTimeout(undefined);
    }, longPressDuration);
    setTouchTimeout(timeoutId);
    setTouchStart(+new Date());
    return true;
  }

  function handleTouchEnd(evt: React.TouchEvent<HTMLElement>) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!touchTimeout) {
      return;
    }
    window.clearTimeout(touchTimeout);
    setTouchTimeout(undefined);
    if (touchStart && (+new Date()) - touchStart < longPressDuration) {
      history.push(PageRoutes.landing);
    }
    return true;
  }

  function handleResetApp() {
    setCachedData(undefined);
    setLocation(undefined);
    setEDLocation(undefined);
    setAsOfDate(undefined);
    window.setTimeout(() => {
      history.replace(PageRoutes.landing);
    }, 0);
  }

  function handleMouseDown(evt: React.MouseEvent<HTMLElement>) {
    if (evt.button === 1 || (evt.button === 0 && evt.ctrlKey)) {
      evt.preventDefault();
      evt.stopPropagation();
      
      showDebugModal();
      return;
    }
    else if (evt.button !== 0) {
      return;
    }
    if (touchTimeout) {
      // ignore multi-touch
      return;
    }
    const timeoutId = window.setTimeout(() => {
      showDebugModal();
      setTouchTimeout(undefined);
    }, longPressDuration);
    setTouchTimeout(timeoutId);
    setTouchStart(+new Date());
  }

  function handleMouseUp(evt: React.MouseEvent<HTMLElement>) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!touchTimeout) {
      return;
    }
    window.clearTimeout(touchTimeout);
    setTouchTimeout(undefined);
    if (touchStart && (+new Date()) - touchStart < longPressDuration) {
      history.push(PageRoutes.landing);
    }
  }

  function handleClick(evt: React.MouseEvent<HTMLElement>) {
    if (history.location.pathname !== PageRoutes.landing) {
      history.push(PageRoutes.landing);
    }
  }

  function modifyDate(newDate: string | DateTime | undefined) {
    if (!newDate) {
      return;
    }
    const d = newDate instanceof DateTime ? newDate : DateTime.fromISO(newDate);
    if (!d.isValid) {
      return;
    }

    setAsOfDate(d.toISODate());

    window.location.reload();
  }

  return (
    <>
      <img src={blackSealLogo} className="App-logo" alt="Seal of Cook County, Illinois"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={handleClick}
      />
      <StaticTextDialog
        show={modalShown}
        onHide={() => setModalShown(false)}
        header="Debug Menu"
        footer={
          <Button variant="link" className="w-25" onClick={() => setModalShown(false)}>Close</Button>
        }
        headerSize="size25"
        className="debug-menu"
      >
        <Row className="mb-4">
          <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>
        </Row>
        <Row className="mb-4">
          <small>Service endpoint: <b>{process.env.REACT_APP_SERVICE_URL}</b></small>
        </Row>
        
        <Row className="mb-4">
          <Col className="col-3">
            <input className="form-control" value={dateString} onChange={evt => setDateString(evt.target.value)} />
          </Col>
          <Col>
            <Button className="form-control" variant="success" onClick={() => modifyDate(dateString)}>Set</Button>
          </Col>
          <Col>
            <Button className="form-control" variant="secondary" onClick={() => modifyDate(DateTime.now().plus({ days: -1 }))}>Yesterday</Button>
          </Col>
          <Col>
            <Button className="form-control" variant="secondary" onClick={() => modifyDate(DateTime.now())}>Today</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="form-control" variant="danger" onClick={handleResetApp}>Reset App</Button>
          </Col>
        </Row>
      </StaticTextDialog>
    </>
  );
}

export default CountySeal;