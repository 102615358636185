import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

import {PageRoutes} from '../../types';
import CountMismatchDialog from '../../components/CountMismatchDialog/CountMismatchDialog';
import StaticTextDialog from '../../components/StaticTextDialog/StaticTextDialog';
import './Placeholder.scss';



  
  function InitialCount() {
    const [modalShow, setModalShow] = React.useState(false);
    const [staticModalShow, setStaticModalShow] = React.useState(false);
    const [previousDayConfirmationModalShow, setPreviousDayConfirmationModalShow] = React.useState(false);

  
    const handleModalSubmit = (inputText: string) => {
      console.log(inputText);
      setModalShow(false);
    }
    
    const handlePreviousDayConfirmSubmit = () => {
      console.log("process data from previous day here if needed");
      setPreviousDayConfirmationModalShow(false);
      setStaticModalShow(true);
    }

    return (
      <>
        <Button variant="primary" onClick={() => setModalShow(true)}>
          Launch vertically centered modal
        </Button>
        
        <Button variant="primary" onClick={() => setStaticModalShow(true)}>
          Launch custom text modal
        </Button>

        <Button variant="primary" onClick={() => setPreviousDayConfirmationModalShow(true)}>
          Launch confirmation modal
        </Button>
  
        <CountMismatchDialog
          show={modalShow}
          onHide={() => setModalShow(false)}
          onSubmit={handleModalSubmit}
        />

        <StaticTextDialog
          show={staticModalShow}
          onHide={() => setStaticModalShow(false)}
          header="Previous Day Count Confirmation"
          footer={
            <Button className="btn-primary w-25" onClick={() => setStaticModalShow(false)}>Close</Button>
          }
          headerSize="size25"
        >
          The totals have been confirmed.
          <br />
          <br />
          Please return to this app at end of day to enter the totals for TODAYS_DATE
        </StaticTextDialog>
        
        <StaticTextDialog
          show={previousDayConfirmationModalShow}
          onHide={() => setPreviousDayConfirmationModalShow(false)}
          header="Previous Day Count Confirmation"
          footer={
            <>
              <Button className="btn-warning spread" onClick={() => setStaticModalShow(false)}>Cancel</Button>
              <Button className="btn-primary spread" onClick={handlePreviousDayConfirmSubmit}>Confirm and Open {/*TODAYS_DATE*/"09/22/22"}</Button>
            </>
          }
          headerSize="size25"
        >
          By submitting, you are confirming that the number from yesterday YESTERDAY'S_DATE are accurate.
          If they are not accurate, please call (312) 603-1178 for assistance.
        </StaticTextDialog>
      </>
    );
  }

export default InitialCount;

