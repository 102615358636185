import "./Reporting.scss";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import useLocalStorage from "use-local-storage";
import CountySeal from "../../components/CountySeal/CountySeal";
import { prevBusinessDay } from "../../sharedFuncs";
import axios from "axios";
import ExcelJs from "exceljs";
import { DateTime } from "luxon";
import { ElectionDay } from "../../types";
import { getConfig } from "../../util/config";
var fileDownload = require("js-file-download");

const serviceURL: string = process.env.REACT_APP_SERVICE_URL as string;

const edMode = "Election Day Reports";
const evMode = "Early Voter Reports";

function Reporting(props: any) {
  //   const [selectedOption, setSelectedOption] = React.useState<{ value: string, label: string } | null>(null);
  const [jwTokenReporting] = useLocalStorage<any>(
    "jwTokenReporting",
    undefined
  );
  const [isElectionDay, setIsElectionDay] = React.useState(true);
  const [canSelect, setCanSelect] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<any | null>("");

  type reportDaypart = "morning" | "night";
  type reportVariant = "simple" | "historical";

  const formatDate = (date: any) =>
    DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);

  React.useEffect(() => {
    const config = getConfig();

    const asOfDate = DateTime.now().toISODate();
    if (DateTime.fromISO(asOfDate) < config.edStartDate) {
      setIsElectionDay(false);
    } else {
      setSelectedOption(edMode);
      setCanSelect(true);
    }
  }, []);

  const sumCounts = (counts: any) => {
    const sum = Object.values(counts).reduce((a: any, b: any) => a + b, 0);
    return sum;
  };

  async function downloadReport(
    dayPart: reportDaypart,
    variant: reportVariant
  ) {
    const reportDate =
      dayPart === "morning" ? prevBusinessDay(DateTime.now()) : DateTime.now();

    console.log(
      ` download report; isElectionDay: ${isElectionDay}; dayPart: ${dayPart}`
    );

    let dupCount = 0;

    if (isElectionDay) {
      axios
        .get(serviceURL + `/electionday/reports/${dayPart}`, {
          headers: {
            "x-access-token": jwTokenReporting,
          },
        })
        .then((result) => {
          console.log(result);

          const scrData = result.data as ElectionDay[];

          // This is a reporting hack.  Some how, about 200 rows were double inserted.  This logic tries to identify
          // the duplicates and adjust accordingly.  Need to investigate how the double entries were created.
          let vscMap = new Map<string, ElectionDay>();
          scrData.forEach((item: ElectionDay) => {
            if (item.edLocation.vscCode !== "xx7100023") {  // leaving in for debugging
              if (vscMap.has(item.edLocation.vscCode)) {
                let existing: ElectionDay | undefined = vscMap.get(
                  item.edLocation.vscCode
                );
                if (existing) {
                  console.log("dupe new:      ", item);
                  console.log("dupe existing: ", existing);
                  ++dupCount;

                  if (
                    item.applicationReceipts < existing.applicationReceipts &&
                    item.applicationReceipts > 0
                  ) {
                    existing.applicationReceipts = item.applicationReceipts;
                    existing.applicationCheckins = item.applicationCheckins;
                    existing.ballotsProvisional = item.ballotsProvisional;
                    existing.ballotsScanner = item.ballotsScanner;
                  }

                  // if (!!!existing.inputtedText) {
                  //   existing.inputtedText = item.inputtedText;
                  // }
                  vscMap.set(item.edLocation.vscCode, existing);
                }
              } else {
                vscMap.set(item.edLocation.vscCode, item);
              }
            }
          });

          let pruned: ElectionDay[] = [];

          vscMap.forEach((value: ElectionDay) => {
            pruned.push(value);
          });

          console.log("duplicate count: ", dupCount);

          const ballotStyles = pruned
            .flatMap((x) => x.edPartyTotals)
            .flatMap((x) =>
              x.edBallotStyleTotals.map(
                (y) =>
                  `${x.edParty.identifier[0].toUpperCase()}${x.edParty.identifier.substring(
                    1
                  )} ${y.ballotStyleNumber}`
              )
            )
            .sort()
            .filter((x, i, a) => i === a.indexOf(x));

          let workbook = new ExcelJs.Workbook();
          let ballotWorksheet = workbook.addWorksheet("Ballot Count");
          ballotWorksheet.columns = [
            { header: "Location", key: "location", width: 60 },
            { header: "VscCode", key: "vscCode", width: 20 },
            ...ballotStyles
              .flatMap((x) => [
                `${x} ${dayPart}`.toLowerCase().replace(/[ -]+/g, "_"),
                `${x} spoiled`.toLowerCase().replace(/[ -]+/g, "_"),
                `${x} unused`.toLowerCase().replace(/[ -]+/g, "_"),
              ])
              .map((header) => {
                return {
                  header,
                  key: header.toLowerCase().replace(/[ -]+/, "_"),
                  width: 20,
                };
              }),
            { header: "Edit Time", key: "dateTimeIso", width: 30 },
          ];

          let eodWorksheet = workbook.addWorksheet("Net Count");
          eodWorksheet.columns = [
            { header: "Location", key: "location", width: 40 },
            { header: "VscCode", key: "vscCode", width: 20 },
            { header: "Initial Count", key: "initial", width: 20 },
            { header: "Spoiled Count", key: "spoiled", width: 20 },
            { header: "Unused Count", key: "unused", width: 20 },
          ];

          let resultsWorksheet = workbook.addWorksheet("Results");
          resultsWorksheet.columns = [
            { header: "Location", key: "location", width: 60 },
            { header: "VscCode", key: "vscCode", width: 20 },
            {
              header: "Application Receipts",
              key: "applicationReceipts",
              width: 20,
            },
            {
              header: "Application Checkins",
              key: "applicationCheckins",
              width: 20,
            },
            {
              header: "Ballots Provisional",
              key: "ballotsProvisional",
              width: 20,
            },
            { header: "Ballots Scanner", key: "ballotsScanner", width: 20 },
            { header: "Edit Time", key: "dateTimeIso", width: 30 },
            { header: "Explanation", key: "explanation", width: 60 },
          ];

          pruned
            .sort((a: any, b: any) =>
              a.edLocation.township.localeCompare(b.edLocation.township)
            )
            .forEach((electionDayObj: any) => {
            //   console.log(electionDayObj);

              const counts = (electionDayObj as ElectionDay).edPartyTotals
                .flatMap((x) =>
                  x.edBallotStyleTotals.map((y) => {
                    return {
                      key: `${x.edParty.identifier} ${y.ballotStyleNumber} ${dayPart}`
                        .toLowerCase()
                        .replace(/[ -]+/g, "_"),
                      value: y.ballotValue,
                    };
                  })
                )
                .reduce((p, c) => {
                  p[c.key] = c.value;
                  return p;
                }, {} as { [key: string]: number });

              const countsSpoiled = (
                electionDayObj as ElectionDay
              ).edPartySpoiledTotals
                .flatMap((x) =>
                  x.edBallotStyleTotals.map((y) => {
                    return {
                      key: `${x.edParty.identifier} ${y.ballotStyleNumber} spoiled`
                        .toLowerCase()
                        .replace(/[ -]+/g, "_"),
                      value: y.ballotValue,
                    };
                  })
                )
                .reduce((p, c) => {
                  p[c.key] = c.value;
                  return p;
                }, {} as { [key: string]: number });

              const countsUnused = (
                electionDayObj as ElectionDay
              ).edPartyUnusedTotals
                .flatMap((x) =>
                  x.edBallotStyleTotals.map((y) => {
                    return {
                      key: `${x.edParty.identifier} ${y.ballotStyleNumber} unused`
                        .toLowerCase()
                        .replace(/[ -]+/g, "_"),
                      value: y.ballotValue,
                    };
                  })
                )
                .reduce((p, c) => {
                  p[c.key] = c.value;
                  return p;
                }, {} as { [key: string]: number });

              ballotWorksheet.addRow({
                location: electionDayObj.edLocation.township,
                vscCode: electionDayObj.edLocation.vscCode || "-",
                ...counts,
                ...countsSpoiled,
                ...countsUnused,
                dateTimeIso: formatDate(electionDayObj.updatedAt),
              });

              eodWorksheet.addRow({
                location: electionDayObj.edLocation.township,
                vscCode: electionDayObj.edLocation.vscCode || "-",
                initial: sumCounts(counts),
                spoiled: sumCounts(countsSpoiled),
                unused: sumCounts(countsUnused),
              });

            //   if (electionDayObj.edLocation.vscCode === "7100023") {
            //       console.log("7100023", electionDayObj);
            //   }

              resultsWorksheet.addRow({
                location: electionDayObj.edLocation.township,
                vscCode: electionDayObj.edLocation.vscCode || "-",
                applicationReceipts: electionDayObj.applicationReceipts,
                applicationCheckins: electionDayObj.applicationCheckins,
                ballotsProvisional: electionDayObj.ballotsProvisional,
                ballotsScanner: electionDayObj.ballotsScanner,
                dateTimeIso: formatDate(electionDayObj.updatedAt),
                explanation: electionDayObj.inputtedText.replace(/\n/g, "  --  "),
              });
            });
          workbook.xlsx.writeBuffer().then((data) => {
            const fileName = `${reportDate.toISODate()}-${dayPart}Report-${variant}.xlsx`;
            fileDownload(data, fileName);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("earlyVoting query called.");
      axios
        .get(serviceURL + `/earlyVoting/reports/${dayPart}`, {
          headers: {
            "x-access-token": jwTokenReporting,
          },
        })
        .then((result) => {
          let workbook = new ExcelJs.Workbook();
          const earlyVotingsByDate = result.data.reduce(
            (acc: any, curr: { date: string }) => {
              if (!acc[curr.date]) acc[curr.date] = [curr];
              else acc[curr.date].push(curr);
              return acc;
            },
            {}
          );

          let wsReconcile = workbook.addWorksheet("Reconcile");
          wsReconcile.columns = [
            { header: "Date", key: "date", width: 10 },
            {
              header: "Overall Application Receipts",
              key: "applicationReceipts",
              width: 25,
            },
            {
              header: "Daily Application Receipts",
              key: "applicationReceiptsDaily",
              width: 25,
            },
            {
              header: "Overall Application Checkins",
              key: "applicationCheckins",
              width: 25,
            },
            {
              header: "Daily Application Checkins",
              key: "applicationCheckinsDaily",
              width: 25,
            },
            {
              header: "Overall Ballots Provisional",
              key: "ballotsProvisional",
              width: 25,
            },
            {
              header: "Daily Ballots Provisional",
              key: "ballotsProvisionalDaily",
              width: 25,
            },
            {
              header: "Overall Ballots Scanner",
              key: "ballotsScanner",
              width: 25,
            },
            {
              header: "Daily Ballots Scanner",
              key: "ballotsScannerDaily",
              width: 25,
            },
          ];

          let previousDayDate: any = null;
          Object.keys(earlyVotingsByDate)
            .sort()
            .forEach((resultDate) => {
              const dateTab = DateTime.fromISO(resultDate)
                .setZone("UTC")
                .toFormat("LLL dd");
              let worksheet = workbook.addWorksheet(dateTab);
              worksheet.columns = [
                { header: "Location", key: "location", width: 60 },
                // {header: "VscCode", key: "vscCode", width: 20},
                {
                  header: "Overall Application Receipts",
                  key: "applicationReceipts",
                  width: 25,
                },
                {
                  header: "Daily Application Receipts",
                  key: "applicationReceiptsDaily",
                  width: 25,
                },
                {
                  header: "Overall Application Checkins",
                  key: "applicationCheckins",
                  width: 25,
                },
                {
                  header: "Daily Application Checkins",
                  key: "applicationCheckinsDaily",
                  width: 25,
                },
                {
                  header: "Overall Ballots Provisional",
                  key: "ballotsProvisional",
                  width: 25,
                },
                {
                  header: "Daily Ballots Provisional",
                  key: "ballotsProvisionalDaily",
                  width: 25,
                },
                {
                  header: "Overall Ballots Scanner",
                  key: "ballotsScanner",
                  width: 25,
                },
                {
                  header: "Daily Ballots Scanner",
                  key: "ballotsScannerDaily",
                  width: 25,
                },
                { header: "Device Id", key: "deviceId", width: 20 },
                { header: "Edit Time", key: "dateTimeIso", width: 30 },
                { header: "Explanation", key: "explanation", width: 60 },
              ];

              const daySum = {
                applicationReceipts: 0,
                applicationReceiptsDaily: 0,
                applicationCheckins: 0,
                applicationCheckinsDaily: 0,
                ballotsProvisional: 0,
                ballotsProvisionalDaily: 0,
                ballotsScanner: 0,
                ballotsScannerDaily: 0,
              };

              earlyVotingsByDate[resultDate]
                .sort((a: any, b: any) =>
                  a.location.displayText.localeCompare(b.location.displayText)
                )
                .forEach((earlyVotingObj: any) => {
                  const sortedHistory = earlyVotingObj.history.sort(
                    (a: any, b: any) =>
                      DateTime.fromISO(a.date) < DateTime.fromISO(b.date)
                        ? 1
                        : -1
                  );

                  const previousDayObj = previousDayDate
                    ? earlyVotingsByDate[previousDayDate].find(
                        (prevLocation: any) =>
                          prevLocation.location.locationId ===
                          earlyVotingObj.location.locationId
                      ) || {
                        applicationReceipts: 0,
                        applicationCheckins: 0,
                        ballotsProvisional: 0,
                        ballotsScanner: 0,
                      }
                    : {
                        applicationReceipts: 0,
                        applicationCheckins: 0,
                        ballotsProvisional: 0,
                        ballotsScanner: 0,
                      };

                  worksheet.addRow({
                    location: earlyVotingObj.location.displayText,
                    // vscCode: earlyVotingObj.location.vscCode || "-",
                    applicationReceipts: earlyVotingObj.applicationReceipts,
                    applicationReceiptsDaily:
                      earlyVotingObj.applicationReceipts -
                      previousDayObj.applicationReceipts,
                    applicationCheckins: earlyVotingObj.applicationCheckins,
                    applicationCheckinsDaily:
                      earlyVotingObj.applicationCheckins -
                      previousDayObj.applicationCheckins,
                    ballotsProvisional: earlyVotingObj.ballotsProvisional,
                    ballotsProvisionalDaily:
                      earlyVotingObj.ballotsProvisional -
                      previousDayObj.ballotsProvisional,
                    ballotsScanner: earlyVotingObj.ballotsScanner,
                    ballotsScannerDaily:
                      earlyVotingObj.ballotsScanner -
                      previousDayObj.ballotsScanner,
                    deviceId: sortedHistory[0].deviceId,
                    dateTimeIso: formatDate(sortedHistory[0].date),
                    explanation: earlyVotingObj.inputtedText,
                  });

                  daySum.applicationReceipts +=
                    earlyVotingObj.applicationReceipts;
                  daySum.applicationReceiptsDaily +=
                    earlyVotingObj.applicationReceipts -
                    previousDayObj.applicationReceipts;
                  daySum.applicationCheckins +=
                    earlyVotingObj.applicationCheckins;
                  daySum.applicationCheckinsDaily +=
                    earlyVotingObj.applicationCheckins -
                    previousDayObj.applicationCheckins;
                  daySum.ballotsProvisional +=
                    earlyVotingObj.ballotsProvisional;
                  daySum.ballotsProvisionalDaily +=
                    earlyVotingObj.ballotsProvisional -
                    previousDayObj.ballotsProvisional;
                  daySum.ballotsScanner += earlyVotingObj.ballotsScanner;
                  daySum.ballotsScannerDaily +=
                    earlyVotingObj.ballotsScanner -
                    previousDayObj.ballotsScanner;

                  if (variant === "historical") {
                    sortedHistory.forEach((historyObj: any) => {
                      worksheet.addRow({
                        location: earlyVotingObj.location.displayText,
                        // vscCode: earlyVotingObj.location.vscCode || "-",
                        applicationReceipts: historyObj.applicationReceipts,
                        applicationCheckins: historyObj.applicationCheckins,
                        ballotsProvisional: historyObj.ballotsProvisional,
                        ballotsScanner: historyObj.ballotsScanner,
                        deviceId: historyObj.deviceId,
                        dateTimeIso: formatDate(historyObj.date),
                        explanation: "(history row)",
                      });
                    });
                  }
                });

              wsReconcile.addRow({
                date: dateTab,
                applicationReceipts: daySum.applicationReceipts,
                applicationReceiptsDaily: daySum.applicationReceiptsDaily,
                applicationCheckins: daySum.applicationCheckins,
                applicationCheckinsDaily: daySum.applicationCheckinsDaily,
                ballotsProvisional: daySum.ballotsProvisional,
                ballotsProvisionalDaily: daySum.ballotsProvisionalDaily,
                ballotsScanner: daySum.ballotsScanner,
                ballotsScannerDaily: daySum.ballotsScannerDaily,
              });
              previousDayDate = resultDate;
            });
          workbook.xlsx.writeBuffer().then((data) => {
            const fileName = `${DateTime.now().toISODate()}-${dayPart}Report-${variant}.xlsx`;
            fileDownload(data, fileName);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
    setIsElectionDay(event.target.value === edMode);
  };

  return (
    <>
      <Container className="reporting">
        <Row>
          <Col className="col-9">&nbsp;</Col>
          <Col className="col-3">
            {canSelect ? (
              <select value={selectedOption} onChange={handleSelectChange}>
                <option>{evMode}</option>
                <option>{edMode}</option>
              </select>
            ) : (
              <h6>
                {isElectionDay ? <div>{edMode}</div> : <div>{evMode}</div>}
              </h6>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <CountySeal />
          </Col>
        </Row>

        <Row className="first-button">
          <h2>Morning Report (all confirmed data before today)</h2>
        </Row>

        <Row>
          <Col>
            <Button
              variant="primary"
              className="large-button morning"
              onClick={() => downloadReport("morning", "simple")}
            >
              Simple
            </Button>
          </Col>
          {!isElectionDay && (
            <Col>
              <Button
                variant="primary"
                className="large-button morning"
                onClick={() => downloadReport("morning", "historical")}
              >
                Historical
              </Button>
            </Col>
          )}
        </Row>

        <Row>
          <h2>Night Report (all data before and including today)</h2>
        </Row>
        <Row>
          <Col>
            <Button
              variant="primary"
              className="large-button night"
              onClick={() => downloadReport("night", "simple")}
            >
              Simple
            </Button>
          </Col>
          {!isElectionDay && (
            <Col>
              <Button
                variant="primary"
                className="large-button night"
                onClick={() => downloadReport("night", "historical")}
              >
                Historical
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

export default Reporting;
