import './EVCountVerification.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import EVPageHeader from '../../components/EVPageHeader/EVPageHeader';
import React, { useEffect, useState } from 'react';
import CountMismatchDialog from '../../components/CountMismatchDialog/CountMismatchDialog';
import StaticTextDialog from '../../components/StaticTextDialog/StaticTextDialog';
import PreviousDayConfirmationDialog from '../../components/PreviousDayConfirmationDialog/PreviousDayConfirmationDialog';
import InfoModal from '../../components/InfoModal/InfoModal';
import useLocalStorage from 'use-local-storage';
import { DateTime } from 'luxon';
import { AiFillInfoCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { PageRoutes, ICheckInData, Location } from '../../types'; 
import { prevBusinessDay } from "../../sharedFuncs";
import axios from 'axios';
import { classNames } from 'react-select/dist/declarations/src/utils';

const serviceURL : string = (process.env.REACT_APP_SERVICE_URL as string);


interface IEVCountVerificationProps {
}

interface EarlyVotingMiniSchema {
  _id: String,
  location: {
    locationId: String,
    displayText: String,
    vscCode: String
  },
  date: Date,
  applicationReceipts: Number,
  applicationCheckins: Number,
  ballotsProvisional: Number,
  ballotsScanner: Number,
  completed: Boolean,
  inputtedText: String,
  history: [{
    // date: Date,
    deviceId: String, // device that made the edit
    applicationReceipts: Number,
    applicationCheckins: Number,
    ballotsProvisional: Number,
    ballotsScanner: Number
  }]
}

interface IPreviousDayConfirmationNoSaveProps {
  confirmDate: string;
  show?: boolean;
  onHide?: () => void;
  onConfirm?: () => void;
}

function PreviousDayConfirmationNoSaveDialog(props: IPreviousDayConfirmationNoSaveProps) {
  return (
      <StaticTextDialog
        show={props.show}
        onHide={props.onHide}
        header="Previous Day Already Confirmed"
        footer={
          <>
            <Button className="btn-warning spread" onClick={props.onHide}>Cancel</Button>
            <Button className="btn-primary spread" onClick={props.onConfirm}>Open {DateTime.now().toFormat('MMMM d, yyyy')}</Button>
          </>
        }
        headerSize="size25"
      >
        All previous dates at this location have already been confirmed.
      </StaticTextDialog>
  );
}

function EVCountVerification() {
  const [location, setLocation] = useLocalStorage<Location | null>('location', null);
  const [jwToken] = useLocalStorage<any>("jwToken", undefined);

  const [cachedData, setCachedData] = useLocalStorage<ICheckInData | undefined>('cachedData', undefined);
  const [checkInData, setCheckInData] = useState<ICheckInData>();

  const [asOfDate, setAsOfDate] = useLocalStorage<string>("asOfDate", DateTime.now().toISODate());

  const [isEditing, setIsEditing] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [receiptCount, setReceiptCount] = useState<string>();
  const [checkInCount, setCheckInCount] = useState<string>();
  const [provisionalVoterCount, setProvisionalVoterCount] = useState<string>();
  const [scannerResultCount, setScannerResultCount] = useState<string>();
  const [formattedDate, setFormattedDate] = useState("");
  const [countMismatchShown, setCountMismatchShown] = useState(false);
  const [previousDayConfirmationShown, setPreviousDayConfirmationShown] = useState(false);
  const [previousDayConfirmationNoSaveShown, setPreviousDayConfirmationNoSaveShown] = useState(false);
  const [earlyVotingObject, setEarlyVotingObject] = useState<EarlyVotingMiniSchema>();

  const [infoModalShown, setInfoModalShown] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [infoClose, setInfoClose] = useState(() => { });
  const history = useHistory();

  const numberFormatter = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0, });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      // Simulate a delay in data loading
      await new Promise(resolve => setTimeout(resolve, 1000));

      console.log('useEffect 1: creating checkInData');
      // TODO: load check in data from source
      const rval: ICheckInData = cachedData || {
        receiptCount: 0,
        checkInCount: 0,
        provisionalVoterCount: 0,
        scannerResultCount: 0,
        mismatchReason: "",
        confirmed: false
      };
      rval.totalVoterCount = rval.provisionalVoterCount + rval.scannerResultCount;
      setCheckInData(rval);
      // Clear the loading flag
      setIsLoading(false);
    })();
  }, [cachedData]);

  useEffect(() => {
    console.log("verify");
    // Parse and format the date
    // const asOfDate = cachedData ? DateTime.fromISO(cachedData.asOfDate) : DateTime.invalid("junk");
    const date = DateTime.fromISO(asOfDate);
    if (date.isValid) {
      const formattedDate = date.toFormat("LLLL d, yyyy");
      
      console.log('useEffect date:', formattedDate);

      setFormattedDate(formattedDate);
    }
    else {
      console.log('useEffect date not valid');
    }
  }, [cachedData, isEditing]); //TODO: add asOf date to this list if need it

  useEffect(() => {
    setIsLoading(true);
    axios.get(serviceURL + `/earlyVoting/latest/${location?._id}`, {
      headers: {
        'x-access-token': jwToken
      }
    })
    .then((result) => {
        console.log(result);
        setReceiptCount(result.data.applicationReceipts ? result.data.applicationReceipts.toString() : "");
        setCheckInCount(result.data.applicationCheckins ? result.data.applicationCheckins.toString() : "");
        setProvisionalVoterCount(result.data.ballotsProvisional ? result.data.ballotsProvisional.toString() : "");
        setScannerResultCount(result.data.ballotsScanner ? result.data.ballotsScanner.toString() : "");
        setEarlyVotingObject(typeof result.data === "string" ? {} : result.data);
        if(DateTime.fromISO(result.data?.date).isValid){
          setAsOfDate(DateTime.fromISO(result.data?.date).setZone("UTC").toISODate());
        }

        // if latest for this location that is also valid exists, load these values here.
        // if latest for this location that is also valid does not exist, set to 0 here
        // console.log('items', result);
      },
      (error) => {
        console.log(error)
      }
    )
    
    setIsLoading(false);
  }, [location]);

  useEffect(() => {
    const isEditable = DateTime.fromISO(asOfDate).hasSame(DateTime.now(), 'day');
    setIsEditing(isEditable);
    setIsEditable(isEditable);
  }, [asOfDate])

  const totalVoterCount = (parseInt(provisionalVoterCount || "") || 0) + (parseInt(scannerResultCount || "") || 0);


  function beginEditing() {
    const rval = cachedData || {
      receiptCount: earlyVotingObject?.applicationReceipts || 0,
      checkInCount: earlyVotingObject?.applicationCheckins || 0,
      provisionalVoterCount: earlyVotingObject?.ballotsProvisional || 0,
      scannerResultCount: earlyVotingObject?.ballotsScanner || 0
    };
    setReceiptCount(rval.receiptCount ? rval.receiptCount.toString() : "");
    setCheckInCount(rval.checkInCount ? rval.checkInCount.toString() : "");
    setProvisionalVoterCount(rval.provisionalVoterCount ? rval.provisionalVoterCount.toString() : "");
    setScannerResultCount(rval.scannerResultCount ? rval.scannerResultCount.toString() : "");
    setIsEditing(true);
  }

  async function saveChanges() {
    if (totalVoterCount !== (parseInt(checkInCount || "") || 0)) {
      setCountMismatchShown(true);
      return;
    }
    
    await saveChangesWithReason();
  }

  async function saveChangesWithReason(reason: string = "") {
    setCountMismatchShown(false);
    setIsLoading(true);

    if(earlyVotingObject?._id) {
      axios.put(`${serviceURL}/earlyVoting/${earlyVotingObject?._id}`,
        {
          location: {
            locationId: location?._id,
            displayText: location?.displayText,
            vscCode: ''
          },
          date: earlyVotingObject.date,
          applicationReceipts: receiptCount || 0,
          applicationCheckins: checkInCount || 0,
          ballotsProvisional: provisionalVoterCount || 0,
          ballotsScanner: scannerResultCount || 0,
          completed: false,
          inputtedText: reason,
          history: earlyVotingObject.history
        }, {
          headers: {
            'x-access-token': jwToken
          }
        }
      )
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
          console.log(error)
      });
    } else {
      axios.post(serviceURL + "/earlyVoting",
        {
          location: {
            locationId: location?._id,
            displayText: location?.displayText,
            vscCode: ''
          },
          date: DateTime.fromISO(asOfDate).toISO(),
          applicationReceipts: receiptCount || 0,
          applicationCheckins: checkInCount || 0,
          ballotsProvisional: provisionalVoterCount || 0,
          ballotsScanner: scannerResultCount || 0,
          completed: false,
          inputtedText: reason
        }, {
          headers: {
            'x-access-token': jwToken
          }
        }
      )
      .then((result) => {
          console.log(result);

        // once we post we get the data and reload it internally. so if user does the initial 'post' and
        // then edits the page, they will then do a 'put' because we will have the _id field
        
        setEarlyVotingObject(typeof result.data === "string" ? {} : result.data);

        showInfoModal(
          "Today's totals have been confirmed.",
          () => {
            setLocation(null);
            window.setTimeout(() => {
              // history.go(0);
              history.replace(PageRoutes.landing);
            }, 0);
          });
      })
      .catch((error) => {
          console.log(error)
      });
    }
    
    // Simulate network delay
    await new Promise(resolve => setTimeout(resolve, 1000));

    const rval: ICheckInData = {
      receiptCount: parseInt(receiptCount || "") || 0,
      checkInCount: parseInt(checkInCount || "") || 0,
      provisionalVoterCount: parseInt(provisionalVoterCount || "") || 0,
      scannerResultCount: parseInt(scannerResultCount || "") || 0,
      mismatchReason: reason,
      confirmed: true
    };
    rval.totalVoterCount = rval.provisionalVoterCount + rval.scannerResultCount;
    setCachedData(rval);
    setCheckInData(rval);
    setReceiptCount(rval.receiptCount ? rval.receiptCount.toString() : "");
    setCheckInCount(rval.checkInCount ? rval.checkInCount.toString() : "");
    setProvisionalVoterCount(rval.provisionalVoterCount ? rval.provisionalVoterCount.toString() : "");
    setScannerResultCount(rval.scannerResultCount ? rval.scannerResultCount.toString() : "");
    setIsEditing(false);
    setIsLoading(false);
  }

  function handleContinue() {
    setPreviousDayConfirmationShown(true);
  }

  function handleContinueNoSave() {
    setPreviousDayConfirmationNoSaveShown(true);
  }

  function showInfoModal(message: string, onClose?: () => void) {
    setInfoClose(() => {
      return () => {
        if (onClose instanceof Function) {
          onClose();
        }
        else {
          setInfoModalShown(false);
        }
      }
    });
    setInfoMessage(message);
    setInfoModalShown(true);
  }

  function handlePreviousDayConfirmation() {
    setPreviousDayConfirmationShown(false);
    setCachedData({
      receiptCount: 0,
      checkInCount: 0,
      provisionalVoterCount: 0,
      scannerResultCount: 0,
      mismatchReason: '',
      confirmed: false,
    });

    axios.put(`${serviceURL}/earlyVoting/${earlyVotingObject?._id}`,
        {
          location: {
            locationId: location?._id,
            displayText: location?.displayText,
            vscCode: ''
          },
          date: earlyVotingObject?.date,
          applicationReceipts: receiptCount || 0,
          applicationCheckins: checkInCount || 0,
          ballotsProvisional: provisionalVoterCount || 0,
          ballotsScanner: scannerResultCount || 0,
          completed: true, // only change
          inputtedText: earlyVotingObject?.inputtedText,
          history: earlyVotingObject?.history
        }, {
          headers: {
            'x-access-token': jwToken
          }
        }
      )
      .then((result) => {

        /// after we confirm to true, let's drop the local cached data.
        setCachedData(undefined);

        // set the asOfDate so when we hit the edit screen we will reset it to today and thus edit mode.
        setAsOfDate(DateTime.now().toISODate());

        showInfoModal(
          "Previous day's totals have been confirmed. Return to this screen at the end of the day to record the day's totals.",
          () => {

            setLocation(null);
            window.setTimeout(() => {
              // history.go(0);
              history.replace(PageRoutes.landing);
            }, 0);
          });

        console.log(result);
      })
      .catch((error) => {
          console.log(error)
      });    
  }

  function handlePreviousDayConfirmationNoSave() {
    setPreviousDayConfirmationNoSaveShown(false);


    setCachedData(undefined);

    // set the asOfDate so when we hit the edit screen we will reset it to today and thus edit mode.
    setAsOfDate(DateTime.now().toISODate());

    showInfoModal(
      "Previous day's totals have already been confirmed and not 'resaved'. Return to this screen at the end of the day to record the day's totals.",
      () => {
        setLocation(null);
        window.setTimeout(() => {
          // history.go(0);
          history.replace(PageRoutes.landing);
        }, 0);
      });

  }

  return (
    <>
      <Container fluid className="ev-count-verification">
        <EVPageHeader location={location} title={`${formattedDate} End of Day Summary`} />
        <Row>
          <Col xs={12} lg={6}>
            <Row className="section-header">
              <Col>Applications</Col>
            </Row>
            <Row>
              <Col className="col-6 text-end">Receipts:</Col>
              <Col className={`${isEditing ? "col-5" : "col-3"} text-end`}>
                {isEditing ? (<input type="text" className="form-control" value={receiptCount} onChange={evt => isNaN(Number(evt.target.value)) || setReceiptCount(evt.target.value)} placeholder="Tap to specify" />) : receiptCount || 0}
              </Col>
              <Col className="col-1 text-end">
                {isEditing ? (<AiFillInfoCircle color="#0d6efd" onClick={() => showInfoModal("Enter the combined total of the totals listed on the Check-In receipt envelope.")} />) : ''}
              </Col>
            </Row>
            <Row>
              <Col className="col-6 text-end">Check-Ins:</Col>
              <Col className={`${isEditing ? "col-5" : "col-3"} text-end`}>
                {isEditing ? (<input type="text" className="form-control" value={checkInCount} onChange={evt => isNaN(Number(evt.target.value)) || setCheckInCount(evt.target.value)} placeholder="Tap to specify" />) : checkInCount || 0}
              </Col>
              <Col className="col-1 text-end">
                {isEditing ? (<AiFillInfoCircle color="#0d6efd" onClick={() => showInfoModal("Enter the combined totals of Check-Ins and Provisional from the summary report.")} />) : ''}
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <Row className="section-header">
              <Col>Ballots Cast</Col>
            </Row>
            <Row>
              <Col className="col-6 text-end">Provisional Voters:</Col>
              <Col className={`${isEditing ? "col-5" : "col-3"} text-end`}>
                {isEditing ? (<input type="text" className="form-control" value={provisionalVoterCount} onChange={evt => isNaN(Number(evt.target.value)) || setProvisionalVoterCount(evt.target.value || "")} placeholder="Tap to specify" />) : provisionalVoterCount || 0}
              </Col>
              <Col className="col-1 text-end">
                {isEditing ? (<AiFillInfoCircle color="#0d6efd" onClick={() => showInfoModal("Combine the numbers from each of the 606s. Enter the total number of Provisional Ballot Envelopes in the 'Provisional Voters' box.")} />) : ''}
              </Col>
            </Row>
            <Row>
              <Col className="col-6 text-end">Scanner Results:</Col>
              <Col className={`${isEditing ? "col-5" : "col-3"} text-end`}>
                {isEditing ? (<input type="text" className="form-control" value={scannerResultCount} onChange={evt => isNaN(Number(evt.target.value)) || setScannerResultCount(evt.target.value)} placeholder="Tap to specify" />) : scannerResultCount || 0}
              </Col>
              <Col className="col-1 text-end">
                {isEditing ? (<AiFillInfoCircle color="#0d6efd" onClick={() => showInfoModal("Record the Scanner Ballot Counter number on the 'PM Ballot Count' line of the Audit Log for the Scanner. Enter the number in the 'Scanner Results' box.")} />) : ''}
              </Col>
            </Row>
            <Row>
              <Col className="col-6 text-end">Total Voters:</Col>
              <Col className={`${isEditing ? "col-5" : "col-3"} text-end`}>
                {isEditing ? (<input type="text" className="form-control" disabled value={totalVoterCount} />) : (parseInt(provisionalVoterCount || "") || 0) + (parseInt(scannerResultCount || "") || 0)}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4, offset: 0 }}>
            <Button className="btn-lg" variant="link" disabled={isLoading} onClick={() => {
              setTimeout(() => {
                setLocation(null);
                history.push(PageRoutes.evSelectLocation);
              }, 0);
            }}>Change Location</Button>
          </Col>
          <Col md={{ span: 4, offset: 0 }}>
            {isEditable
              ? isEditing
                ? <Button className="btn-lg" variant="primary" disabled={isLoading} onClick={saveChanges}>Save</Button>
                : (<Button className="btn-lg" variant="primary" disabled={isLoading} onClick={beginEditing}>Edit</Button>)
              : earlyVotingObject?._id
                ? <Button className="btn-lg" variant="primary" disabled={isLoading} onClick={handleContinue}>Continue</Button>
                : <Button className="btn-lg" variant="primary" disabled={isLoading} onClick={handleContinueNoSave}>Continue</Button>
            }
          </Col>
        </Row>
      </Container>
      <CountMismatchDialog show={countMismatchShown} onHide={() => setCountMismatchShown(false)} onSubmit={saveChangesWithReason} reason={checkInData?.mismatchReason} />
      <PreviousDayConfirmationDialog
        confirmDate={formattedDate}
        show={previousDayConfirmationShown}
        onHide={() => setPreviousDayConfirmationShown(false)}
        onConfirm={handlePreviousDayConfirmation}
      />
      <PreviousDayConfirmationNoSaveDialog
        confirmDate={formattedDate}
        show={previousDayConfirmationNoSaveShown}
        onHide={() => setPreviousDayConfirmationNoSaveShown(false)}
        onConfirm={handlePreviousDayConfirmationNoSave}
      />

      <InfoModal show={infoModalShown} onHide={infoClose} message={infoMessage} />
    </>
  );
}

export default EVCountVerification;