import Modal from 'react-bootstrap/Modal';

import "./StaticTextDialog.scss";

function StaticTextDialog({headerSize, header, children, className, footer, ...props}: any) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="modal-title"
        centered
        className={['static-text-dialog', className]}
      >
        <Modal.Header closeButton>
          <Modal.Title className={`w-100 static-text-modal-title ${headerSize}`}>
            {header}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          {footer}
        </Modal.Footer>
      </Modal>
    );
  }

  export default StaticTextDialog