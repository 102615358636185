import { Container, Row, Col } from 'react-bootstrap';
import CountySeal from '../../components/CountySeal/CountySeal';

function AccessDenied(props: any) {

  return (
    <>
      <Container className="reporting">
        <Row>
          <Col><CountySeal /></Col>
        </Row>
        <h1>Access Denied</h1>
        <div>You do not have the correct access token to access this part of the app. There are 2 tokens, one for reporting,
            and one for the rest of the app. Please check your link and confirm.
        </div>
      </Container>
    </>
  );
}


export default AccessDenied;