import Button from 'react-bootstrap/Button';

import './InitializeReporting.scss';
import { PageRoutes } from '../../types';
import useLocalStorage from 'use-local-storage';
import { Redirect, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import CountySeal from '../../components/CountySeal/CountySeal';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';


interface PropTypes {
}

function InitializeReporting(props: PropTypes) {
  const [, setJwTokenReporting] = useLocalStorage<any>("jwTokenReporting", undefined);
  const history = useHistory();

  const search = useLocation().search;
  const jwtReporting = new URLSearchParams(search).get("jwtReporting");
  // console.log('jwt is: ', jwt);//12345

  useEffect(() => {
    setJwTokenReporting(jwtReporting);

    window.setTimeout(() => {
        history.replace(PageRoutes.reporting);
        window.location.reload();
    }, 100);
  });


  return (
    <div className="initializeReporting">
        <CountySeal />
        <Row>
          <Col>
            <h1>Configuring application for reporting...</h1>
          </Col>
        </Row>
        {/* <div className="button-wrap">
          <Button className="large-button btn-lg" variant="primary">Temp Button</Button>
        </div> */}
      

    </div>
  );
}

export default InitializeReporting;