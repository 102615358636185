import { DateTime } from "luxon"

export type ConfigType = {
    // 2 attributes that define when early voting starts, and a failure dialog message 
    // that is displayed when access is requested out of the date range
    evStartDate : DateTime,
    preventEVMessage : string,

    // 2 attributes that define when ElectionDay behavior is available, and the dialog message
    // displayed when access is requested out of range.
    edStartDate : DateTime,
    preventEDMessage : string,

    // For election day end of day numbers, don't allow access until after this time (7:00 pm local CST); should be same day as edStartDate
    edNightStart : DateTime,

    // allow the opening of ED earlier; may be before election
    edPreCount: DateTime,

    // if true, allow EV and ED access; should be true only in test.  Default of false will prevent access to both on the same day.
    allowEVEDParallel : boolean,

    // poor man's build information; doesn't auto update as of yet...
    build: string,
}

const config: ConfigType = {
    evStartDate: DateTime.local(2023, 4, 3),
    preventEVMessage: "before (not including)",  

    edStartDate: DateTime.local(2023, 4, 3),
    preventEDMessage: "on", 

    edNightStart: DateTime.local(2023, 4, 3, 19, 0, 0),

    // allow initial count early
    edPreCount: DateTime.local(2023, 3, 31),

    build: "2023.4.03.a",

    // should be false in production, true in test
    allowEVEDParallel: true,
}

// May make this a hook or a api call in the future...
export function  getConfig():ConfigType {
    return config;
}