import './SBLanding.scss';
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import blackSealLogo from '../../images/CC Seal_Black.png';
import Button from 'react-bootstrap/Button';
import useLocalStorage from 'use-local-storage';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { EDLocation, PageRoutes } from '../../types';
import CountySeal from '../../components/CountySeal/CountySeal';
import axios from 'axios';
import StaticTextDialog from '../../components/StaticTextDialog/StaticTextDialog';

const serviceURL : string = (process.env.REACT_APP_SERVICE_URL as string);

const CodeVerificationDialog = ({show}: {show: Boolean}) => <StaticTextDialog
    show={show}
    header="Please Wait"
    footer={null}
    headerSize="size25"
    className="code-verif"
  >
    Transmitting and validating data with the server...
</StaticTextDialog>;

const InvalidCodeDialog = ({show, onHide}: {show: Boolean, onHide: any}) => <StaticTextDialog
    show={show}
    header="Code Mismatch"
    onHide={onHide}
    footer={<Button 
      variant="primary"
      className="ok-button"
      onClick={onHide}
    >
      OK
    </Button>}
    headerSize="size25"
    className="invalid-code"
  >
    The specified VSC Code is not valid. Please check the code and resubmit.
</StaticTextDialog>;

function SBLanding(props: any) {
//   const [selectedOption, setSelectedOption] = React.useState<{ value: string, label: string } | null>(null);

  const [, setEDLocation] = useLocalStorage<EDLocation | null>('edLocation', null);

  const [securityCode, setSecurityCode] = useState<string>("");
  const [showCodeVerificationDialog, setShowCodeVerificationDialog] = useState(false);
  const [showInvalidCodeDialog, setShowInvalidCodeDialog] = useState(false);
  const [jwToken] = useLocalStorage<any>("jwToken", undefined);
  const [asOfDate, setAsOfDate] = useLocalStorage<string>("asOfDate", DateTime.now().toISODate());

  const history = useHistory();

  // no date check required because won't be able to access this screen before election day
  function contiueClicked() {
    setShowCodeVerificationDialog(true);
    axios.get(serviceURL + `/edLocations/vscCode/${securityCode}`, {
      headers: {
        'x-access-token': jwToken
      }
    })
    .then((result) => {
      if(result.data.length > 0) {
        var edLocation = result.data[0];
        setEDLocation(edLocation);
        continueToNext(edLocation);
      }
      else {
        setShowCodeVerificationDialog(false);
        setShowInvalidCodeDialog(true);
      }
    })
    .catch((error) => {
        setShowCodeVerificationDialog(false);
        setShowInvalidCodeDialog(true);
        console.log(error)
    });
  }

  function continueToNext(edLocation:EDLocation) {
    setShowCodeVerificationDialog(false);
    // Using `setTimeout` here to work around a bug / bad interaction between `react-router-dom` and `use-local-storage`
    setTimeout(() => {

      var dems = edLocation.edPartyBallots.find(pb => pb.edParty.identifier == "dem");
      var reps = edLocation.edPartyBallots.find(pb => pb.edParty.identifier == "rep");
      var libs = edLocation.edPartyBallots.find(pb => pb.edParty.identifier == "lib");
      var grns = edLocation.edPartyBallots.find(pb => pb.edParty.identifier == "grn");
      var nons = edLocation.edPartyBallots.find(pb => pb.edParty.identifier == "non");

      console.log('edLocation', edLocation);
      console.log('fields', dems, reps, libs, grns, nons);

      // history.push(PageRoutes.sbInitialCountDem);
      if (dems != null) {
        history.push(PageRoutes.sbInitialCountDem);
      }
      else if (reps != null) {
        history.push(PageRoutes.sbInitialCountRep);
      }
      else if (libs != null) {
        history.push(PageRoutes.sbInitialCountLib);
      }
      else if (grns != null) {
        history.push(PageRoutes.sbInitialCountGre);
      }
      else if (nons != null) {
        history.push(PageRoutes.sbInitialCountNon);
      }
      else {
        // just forward it for now.
        history.push(PageRoutes.sbInitialCountDem);
      }
    }, 0);
  }

  function continueToReconciliation() {
    setShowCodeVerificationDialog(false);
    // Using `setTimeout` here to work around a bug / bad interaction between `react-router-dom` and `use-local-storage`
    setTimeout(() => {
      history.push(PageRoutes.sbReconciliation);
    }, 0);
  }

  return (
    <>
      <Container className="sb-landing">
        <Row>
          <Col><CountySeal /></Col>
        </Row>

        <Row>
          <Col>
            <h1>Cook County Clerk's Office</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            Statement of Ballots - {DateTime.fromISO(asOfDate).toFormat("LLLL d, yyyy")}
          </Col>
        </Row>

        <Row>
          <Col>
            Enter the 7-digit code found on the VSC:
          </Col>
        </Row>

        <Row>
            <Col>
            <input type="text" value={securityCode} maxLength={7} onChange={evt => isNaN(Number(evt.target.value)) || setSecurityCode(evt.target.value || "")} placeholder="ex. 1234567" />              
            </Col>
        </Row>

        <div className="restOfPage">
          <Button
            variant="primary"
            className="large-button"
            onClick={contiueClicked}
            disabled={!securityCode}
          >
            Continue
          </Button>
        </div>
      </Container>

      <CodeVerificationDialog show={showCodeVerificationDialog} />
      <InvalidCodeDialog
        show={showInvalidCodeDialog}
        onHide={() => setShowInvalidCodeDialog(false)}
      />
    </>

  );
}

/// https://www.cookcountyclerkil.gov/elections/polling-places ///
/// https://www.cookcountyclerkil.gov/elections/ways-to-vote/early-voting/early-voting-locations ///


export default SBLanding;