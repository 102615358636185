import './SBReconciliation.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import SBPageHeader from '../../components/SBPageHeader/SBPageHeader';
import React, { useEffect, useState } from 'react';
import CountMismatchDialog from '../../components/CountMismatchDialog/CountMismatchDialog';
import InfoModal from '../../components/InfoModal/InfoModal';
import useLocalStorage from 'use-local-storage';
import { DateTime } from 'luxon';
import { AiFillInfoCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { PageRoutes, ICheckInData, EDLocation } from '../../types';
import axios from 'axios';

const serviceURL : string = (process.env.REACT_APP_SERVICE_URL as string);


interface IEVCountVerificationProps {
}

interface EarlyVotingMiniSchema {
  _id: String,
  location: {
    locationId: String,
    displayText: String,
    vscCode: String
  },
  date: Date,
  applicationReceipts: Number,
  applicationCheckins: Number,
  ballotsProvisional: Number,
  ballotsScanner: Number,
  completed: Boolean,
  inputtedText: String,
  history: [{
    // date: Date,
    deviceId: String, // device that made the edit
    applicationReceipts: Number,
    applicationCheckins: Number,
    ballotsProvisional: Number,
    ballotsScanner: Number
  }]
}

function EVCountVerification() {
  const [edLocation, setEDLocation] = useLocalStorage<EDLocation | null>('edLocation', null);

  const [cachedData, setCachedData] = useLocalStorage<ICheckInData | undefined>('cachedData', undefined);
  const [checkInData, setCheckInData] = useState<ICheckInData>();

  const [asOfDate, setAsOfDate] = useLocalStorage<string>("asOfDate", DateTime.now().toISODate());

  const [isEditing, setIsEditing] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [receiptCount, setReceiptCount] = useState<string>();
  const [checkInCount, setCheckInCount] = useState<string>();
  const [provisionalVoterCount, setProvisionalVoterCount] = useState<string>();
  const [scannerResultCount, setScannerResultCount] = useState<string>();
  const [formattedDate, setFormattedDate] = useState("");
  const [countMismatchShown, setCountMismatchShown] = useState(false);
  const [previousDayConfirmationShown, setPreviousDayConfirmationShown] = useState(false);
  const [earlyVotingObject, setEarlyVotingObject] = useState<EarlyVotingMiniSchema>();
  const [jwToken] = useLocalStorage<any>("jwToken", undefined);
  const [electionDayObject, setElectionDayObject] = useLocalStorage<any>("electionDayObject", undefined);

  const [infoModalShown, setInfoModalShown] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [infoClose, setInfoClose] = useState(() => { });
  const history = useHistory();

  useEffect(() => {
    axios.get(serviceURL + `/electionDay/latest/${edLocation?._id}`,
    {
      headers: {
        'x-access-token': jwToken
      }
    })
    .then(result => {
      setElectionDayObject(result.data);
    })
    .catch(error => {
      console.log(error);
    })
  }, [])

  function showInfoModal(message: string, onClose?: () => void) {
    setInfoClose(() => {
      return () => {
        if (onClose instanceof Function) {
          onClose();
        }
        else {
          setInfoModalShown(false);
        }
      }
    });
    setInfoMessage(message);
    setInfoModalShown(true);
  }

  async function saveChanges() {
    if (totalVoterCount !== (parseInt(checkInCount || "") || 0)) {
      setCountMismatchShown(true);
      return;
    }
    
    await saveChangesWithReason();
  }

  async function saveChangesWithReason(reason: string = "") {
    setCountMismatchShown(false);
    setIsLoading(true);

    if(electionDayObject?._id) {
      axios.put(`${serviceURL}/electionDay/${electionDayObject?._id}`,
        {
          ...electionDayObject,
          applicationReceipts: receiptCount,
          applicationCheckins: checkInCount,
          ballotsProvisional: provisionalVoterCount,
          ballotsScanner: scannerResultCount,
          inputtedText: reason,
          completed: true
        }, {
          headers: {
            'x-access-token': jwToken
          }
        }
      )
      .then((result) => {
        console.log(result);
        setReceiptCount(result.data.applicationReceipts ? result.data.applicationReceipts.toString() : "");
        setCheckInCount(result.data.applicationCheckins ? result.data.applicationCheckins.toString() : "");
        setProvisionalVoterCount(result.data.ballotsProvisional ? result.data.ballotsProvisional.toString() : "");
        setScannerResultCount(result.data.ballotsScanner ? result.data.ballotsScanner.toString() : "");
        setIsEditing(false);
        showInfoModal(
          "This precinct has been completed. If you need to make changes, contact technical support.", 
          () => {
            setEDLocation(null);
            window.setTimeout(() => {
              history.replace(PageRoutes.landing);
            }, 0);
          });
      })
      .catch((error) => {
          console.log(error)
      });
    } else {
      alert("Error: Please go back to the home screen and follow the election day flow again.")
    }
  }

  const isAnyFieldEmpty = () => {
    console.log(
      receiptCount,
checkInCount,
provisionalVoterCount,
scannerResultCount
    );
    return receiptCount === undefined ||
    checkInCount === undefined ||
    provisionalVoterCount === undefined ||
    scannerResultCount === undefined;
  }

  const totalVoterCount = (parseInt(provisionalVoterCount || "") || 0) + (parseInt(scannerResultCount || "") || 0);

  return (
    <>
      <Container fluid className="ev-count-verification">
        <SBPageHeader edLocation={edLocation} title={`Reconciliation`} />
        <Row>
          <Col xs={12} lg={6}>
            <Row className="section-header">
              <Col>Applications</Col>
            </Row>
            <Row>
              <Col className="col-6 text-end">Receipts:</Col>
              <Col className={`${isEditing ? "col-5" : "col-3"} text-end`}>
                {isEditing ? (<input type="text" className="form-control" value={receiptCount} onChange={evt => isNaN(Number(evt.target.value)) || setReceiptCount(evt.target.value)} placeholder="Tap to specify" />) : receiptCount || 0}
              </Col>
              <Col className="col-1 text-end">
                {isEditing ? (<AiFillInfoCircle color="#0d6efd" onClick={() => showInfoModal("Enter the combined total of the totals listed on the Check-In receipt envelope.")} />) : ''}
              </Col>
            </Row>
            <Row>
              <Col className="col-6 text-end">Check-Ins:</Col>
              <Col className={`${isEditing ? "col-5" : "col-3"} text-end`}>
                {isEditing ? (<input type="text" className="form-control" value={checkInCount} onChange={evt => isNaN(Number(evt.target.value)) || setCheckInCount(evt.target.value)} placeholder="Tap to specify" />) : checkInCount || 0}
              </Col>
              <Col className="col-1 text-end">
                {isEditing ? (<AiFillInfoCircle color="#0d6efd" onClick={() => showInfoModal("Enter the combined totals of Check-Ins and Provisional from the summary report.")} />) : ''}
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <Row className="section-header">
              <Col>Ballots Cast</Col>
            </Row>
            <Row>
              <Col className="col-6 text-end">Provisional Voters:</Col>
              <Col className={`${isEditing ? "col-5" : "col-3"} text-end`}>
                {isEditing ? (<input type="text" className="form-control" value={provisionalVoterCount} onChange={evt => isNaN(Number(evt.target.value)) || setProvisionalVoterCount(evt.target.value || "")} placeholder="Tap to specify" />) : provisionalVoterCount || 0}
              </Col>
              <Col className="col-1 text-end">
                {isEditing ? (<AiFillInfoCircle color="#0d6efd" onClick={() => showInfoModal("Combine the numbers from each of the 606s. Enter the total number of Provisional Ballot Envelopes in the 'Provisional Voters' box.")} />) : ''}
              </Col>
            </Row>
            <Row>
              <Col className="col-6 text-end">Scanner Results:</Col>
              <Col className={`${isEditing ? "col-5" : "col-3"} text-end`}>
                {isEditing ? (<input type="text" className="form-control" value={scannerResultCount} onChange={evt => isNaN(Number(evt.target.value)) || setScannerResultCount(evt.target.value)} placeholder="Tap to specify" />) : scannerResultCount || 0}
              </Col>
              <Col className="col-1 text-end">
                {isEditing ? (<AiFillInfoCircle color="#0d6efd" onClick={() => showInfoModal("Record the Scanner Ballot Counter number on the 'PM Ballot Count' line of the Audit Log for the Scanner. Enter the number in the 'Scanner Results' box.")} />) : ''}
              </Col>
            </Row>
            <Row>
              <Col className="col-6 text-end">Total Voters:</Col>
              <Col className={`${isEditing ? "col-5" : "col-3"} text-end`}>
                {isEditing ? (<input type="text" className="form-control" disabled value={totalVoterCount} />) : (parseInt(provisionalVoterCount || "") || 0) + (parseInt(scannerResultCount || "") || 0)}
              </Col>
            </Row>
          </Col>
        </Row>
        <Button className="btn-lg" variant="primary" disabled={isAnyFieldEmpty()} onClick={saveChanges}>Save</Button>
      </Container>
      <CountMismatchDialog show={countMismatchShown} onHide={() => setCountMismatchShown(false)} onSubmit={saveChangesWithReason} reason={checkInData?.mismatchReason} />

      <InfoModal show={infoModalShown} onHide={infoClose} message={infoMessage} />
    </>
  );
}

export default EVCountVerification;