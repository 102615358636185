import { Party, PartyKey } from "../types";


export function buildTitle(leader: string, partyKey : PartyKey) : string {
   //title={`Initial Count - ${Party[props.party]} Party`} />
   let title = leader;

   if (!!!process.env.REACT_APP_IS_GENERAL_ELECTION) {
      title += ` - ${Party[partyKey]} Party`
   }

   return title;
}