import { DateTime } from 'luxon';
import { Table } from 'react-bootstrap';
import CountySeal from '../../components/CountySeal/CountySeal';
import { getConfig } from '../../util/config';
import './Info.scss';

const isGeneralElection : boolean = (process.env.REACT_APP_IS_GENERAL_ELECTION as unknown as boolean)


function Info() {
    const config = getConfig();
    return (
        <div className="info">
            <CountySeal />
            <div className="button-wrap">
                <Table>
                    <tbody>
                        <tr>
                            <td align='left'>Early Voting Start Date</td>
                            <td align='left'>{config.evStartDate.toLocaleString(DateTime.DATETIME_SHORT)}</td>
                        </tr>
                        <tr>
                            <td align='left'>Election Day Start Date</td>
                            <td align='left'>{config.edStartDate.toLocaleString(DateTime.DATETIME_SHORT)}</td>
                        </tr>
                        <tr>
                            <td align='left'>Election Day Night Guard</td>
                            <td align='left'>{config.edNightStart.toLocaleString(DateTime.DATETIME_SHORT)}</td>
                        </tr>
                        <tr>
                            <td align='left'>Election Day Pre Count Access</td>
                            <td align='left'>{config.edPreCount.toLocaleString(DateTime.DATETIME_SHORT)}</td>
                        </tr>
                        <tr>
                            <td align='left'>Election Style</td>
                            <td align='left'>{isGeneralElection ? 'General Election' : 'Primary Election'}</td>
                        </tr>
                        <tr>
                            <td align='left'>Allow EV + ED access</td>
                            <td align='left'>{config.allowEVEDParallel ? 'true' : 'false'}</td>
                        </tr>
                        <tr>
                            <td align='left'>Build Info</td>
                            <td align='left'>{config.build}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>

    )
}

export default Info;