import './EVPageHeader.scss';
import { Container, Row, Col } from 'react-bootstrap';
import blackSealLogo from '../../images/CC Seal_Black.png';
import Button from 'react-bootstrap/Button';
import CountySeal from '../CountySeal/CountySeal';
import { Location } from '../../types';

interface IEVPageHeaderProps {
  location: Location | null;
  title?: String;
}
function EVPageHeader(props: IEVPageHeaderProps) {
  return (
    <>
      <Row className="header align-items-center">
        <Col className="flex-grow-1 d-flex flex-row justify-content-center">
          <CountySeal />
          <div className="header-text ps-5">
            <h3>Cook County Clerk's Office</h3>
            <p>Early Voting</p>
            <em>{props.location?.displayText}</em>
          </div>
        </Col>
      </Row>
      <Row className="title">
        <Col>
          <h1>{props.title}</h1>
        </Col>
      </Row>
    </>
  );
}
export default EVPageHeader;