import './SBPageHeader.scss';
import { Row, Col } from 'react-bootstrap';
import CountySeal from '../CountySeal/CountySeal';
import { EDLocation } from '../../types';

interface ISBPageHeaderProps {
  edLocation?: EDLocation | null;
  title?: String;
}
function SBPageHeader(props: ISBPageHeaderProps) {
  return (
    <>
      <Row className="header align-items-center">
        <Col className="flex-grow-1 d-flex flex-row justify-content-center">
          <CountySeal />
          <div className="header-text ps-5">
            <h3>Cook County Clerk's Office</h3>
            <p>Statement of Ballots</p>
            <em>{props.edLocation?.township}, ({props.edLocation?.vscCode}) Precinct {props.edLocation?.precint}</em>
          </div>
        </Col>
      </Row>
      <Row className="title">
        <Col>
          <h1>{props.title}</h1>
        </Col>
      </Row>
    </>
  );
}
export default SBPageHeader;